import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { FaPlus } from 'react-icons/fa';
import heroMachineLearning from '../assets/hero-machine-learning.jpg'; 
import './MachineLearning.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';

const MachineLearning = () => {
    const [discussions, setDiscussions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchDiscussions = async () => {
            try {
                const discussionsRef = collection(db, 'machine-learning-discussions');
                const q = query(discussionsRef, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                
                const discussionsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    pinned: doc.data().pinned || false,
                    createdAt: doc.data().createdAt || { seconds: 0 }
                }));

                const sortedDiscussions = discussionsData.sort((a, b) => {
                    if (a.pinned && !b.pinned) return -1;
                    if (!a.pinned && b.pinned) return 1;
                    return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
                });

                setDiscussions(sortedDiscussions);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching discussions:", error);
                setLoading(false);
            }
        };

        fetchDiscussions();
    }, []);

    const handleDiscussionUpdate = async () => {
        const discussionsRef = collection(db, 'machine-learning-discussions');
        const querySnapshot = await getDocs(discussionsRef);
        
        const discussionsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            pinned: doc.data().pinned || false,
            createdAt: doc.data().createdAt || { seconds: 0 }
        }));

        const sortedDiscussions = discussionsData.sort((a, b) => {
            if (a.pinned && !b.pinned) return -1;
            if (!a.pinned && b.pinned) return 1;
            return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
        });

        setDiscussions(sortedDiscussions);
    };

    return (
        <main className="machine-learning-page">
            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Machine Learning</h1>
                        <p>Découvrez les avancées en intelligence artificielle et machine learning, et leurs applications dans notre quotidien</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroMachineLearning}  
                    alt="Visualisation des algorithmes de machine learning avec des réseaux de neurones et des patterns de données" 
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="machine-learning-discussions"
                                onReply={handleDiscussionUpdate}
                            />
                        ))}
                    </div>
                )}
            </section>

            {currentUser && (
                <button 
                    className="new-discussion-button" 
                    title="Nouvelle discussion"
                    onClick={() => setShowModal(true)}
                >
                    <FaPlus />
                </button>
            )}

            <NouvDiscModal 
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                forumId="machine-learning-discussions"
                onDiscussionUpdate={handleDiscussionUpdate}
            />
        </main>
    );
};

export default MachineLearning;
