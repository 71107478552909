import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

export const initHighTechSearch = async () => {
  const searchData = [
    // Smart Home Section
    {
      title: "Domotique et Maison Connectée",
      path: "/high-tech#smart-home",
      section: "high-tech",
      type: "article",
      keywords: ["domotique", "maison connectée", "smart home", "automatisation"],
      searchableTerms: ["domotique", "maison connectée", "smart home", "automatisation", "contrôle", "éclairage intelligent", "thermostat connecté"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Éclairage Intelligent",
      path: "/high-tech#smart-lighting",
      section: "high-tech",
      type: "article",
      keywords: ["éclairage", "ampoules connectées", "LED", "smart lighting"],
      searchableTerms: ["éclairage intelligent", "ampoules connectées", "LED", "contrôle luminosité", "automatisation éclairage"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Sécurité Domestique",
      path: "/high-tech#home-security",
      section: "high-tech",
      type: "article",
      keywords: ["sécurité", "caméras", "serrures connectées", "surveillance"],
      searchableTerms: ["sécurité maison", "caméras surveillance", "serrures connectées", "détecteurs", "alarmes"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Confort et Économies d'Énergie",
      path: "/high-tech#energy-efficiency",
      section: "high-tech",
      type: "article",
      keywords: ["économie énergie", "thermostat", "chauffage intelligent", "climatisation"],
      searchableTerms: ["économies énergie", "thermostat intelligent", "gestion chauffage", "climatisation connectée", "consommation énergétique"],
      lastUpdated: new Date().toISOString()
    },

    // Mobilité Électrique Section
    {
      title: "Véhicules Électriques",
      path: "/high-tech#electric-vehicles",
      section: "high-tech",
      type: "article",
      keywords: ["voiture électrique", "véhicule électrique", "mobilité", "transport"],
      searchableTerms: ["voiture électrique", "véhicule électrique", "batterie", "autonomie", "recharge"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Trottinettes et Vélos Électriques",
      path: "/high-tech#electric-mobility",
      section: "high-tech",
      type: "article",
      keywords: ["trottinette", "vélo électrique", "mobilité urbaine", "micromobilité"],
      searchableTerms: ["trottinette électrique", "vélo électrique", "mobilité douce", "transport urbain", "micromobilité"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Infrastructure de Recharge",
      path: "/high-tech#charging-infrastructure",
      section: "high-tech",
      type: "article",
      keywords: ["borne recharge", "station", "infrastructure", "réseau"],
      searchableTerms: ["borne recharge", "station électrique", "infrastructure recharge", "réseau bornes", "charge rapide"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Innovation Transport",
      path: "/high-tech#transport-innovation",
      section: "high-tech",
      type: "article",
      keywords: ["innovation", "transport", "mobilité future", "technologie"],
      searchableTerms: ["innovation transport", "mobilité future", "nouvelles technologies", "transport durable"],
      lastUpdated: new Date().toISOString()
    },

    // Cybersécurité Section
    {
      title: "Protection des Données",
      path: "/high-tech#cybersecurite",
      section: "high-tech",
      type: "article",
      keywords: ["protection données", "sécurité", "confidentialité", "RGPD"],
      searchableTerms: ["protection données", "sécurité informatique", "confidentialité", "RGPD", "données personnelles"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Sécurité en Ligne",
      path: "/high-tech#cybersecurite",
      section: "high-tech",
      type: "article",
      keywords: ["sécurité en ligne", "protection internet", "cybersécurité", "antivirus"],
      searchableTerms: ["sécurité en ligne", "protection internet", "cybersécurité", "antivirus", "pare-feu"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Menaces Cyber",
      path: "/high-tech#cybersecurite",
      section: "high-tech",
      type: "article",
      keywords: ["menaces", "cyberattaques", "hacking", "malware"],
      searchableTerms: ["menaces cyber", "cyberattaques", "hacking", "malware", "virus", "ransomware"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Bonnes Pratiques",
      path: "/high-tech#cybersecurite",
      section: "high-tech",
      type: "article",
      keywords: ["bonnes pratiques", "sécurité", "conseils", "protection"],
      searchableTerms: ["bonnes pratiques sécurité", "conseils cybersécurité", "protection données", "sécurité informatique"],
      lastUpdated: new Date().toISOString()
    },

    // Green Tech Section
    {
      title: "Technologies Vertes",
      path: "/high-tech#green-tech",
      section: "high-tech",
      type: "article",
      keywords: ["technologie verte", "écologie", "développement durable", "innovation"],
      searchableTerms: ["technologie verte", "écologie", "développement durable", "innovation environnementale"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Énergies Renouvelables",
      path: "/high-tech#renewable-energy",
      section: "high-tech",
      type: "article",
      keywords: ["énergie renouvelable", "solaire", "éolien", "hydraulique"],
      searchableTerms: ["énergie renouvelable", "solaire", "éolien", "hydraulique", "transition énergétique"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Recyclage Technologique",
      path: "/high-tech#tech-recycling",
      section: "high-tech",
      type: "article",
      keywords: ["recyclage", "déchets électroniques", "économie circulaire", "réparation"],
      searchableTerms: ["recyclage technologique", "déchets électroniques", "économie circulaire", "réparation appareils"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Impact Environnemental",
      path: "/high-tech#environmental-impact",
      section: "high-tech",
      type: "article",
      keywords: ["impact environnemental", "empreinte carbone", "durabilité", "écologie"],
      searchableTerms: ["impact environnemental", "empreinte carbone", "durabilité", "écologie numérique"],
      lastUpdated: new Date().toISOString()
    }
  ];

  try {
    for (const item of searchData) {
      await addDoc(collection(db, 'searchIndex'), item);
    }
    return true;
  } catch (error) {
    return false;
  }
};
