import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer-content">
                <div className="footer-logo">
                    <Link to="/">
                        <span className="tech">Tech</span>
                        <span className="passion">Passion</span>
                    </Link>
                </div>
                <div className="footer-bottom">
                    <p className="footer-copyright">© 2024 TechPassion. Tous droits réservés.</p>
                    <div className="footer-links">
                        <Link to="/mentions-legales">Mentions légales</Link>
                        <Link to="/politique-confidentialite">Politique de confidentialité</Link>
                        <Link to="/contact">Contact</Link>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
