import React from 'react';
import { FaCrown } from 'react-icons/fa';
import './index.css';

const UserBadge = ({ badge }) => {
  if (!badge) return null;

  // Si badge est une chaîne de caractères, convertir en objet
  const badgeConfig = typeof badge === 'string' ? getBadgeConfig(badge) : badge;

  const badgeClasses = `user-badge ${badgeConfig.type || ''}`;

  return (
    <span className={badgeClasses}>
      {badgeConfig.icon} {badgeConfig.label}
    </span>
  );
};

// Fonction pour obtenir la configuration du badge à partir d'un type
const getBadgeConfig = (badgeType) => {
  const configs = {
    admin: {
      type: 'admin',
      icon: <FaCrown />,
      label: 'Admin'
    }
    // Ajouter d'autres types de badges ici si nécessaire
  };

  return configs[badgeType] || null;
};

export default UserBadge;
