import React, { useState } from 'react';

const AvatarSelector = ({ onSelect, currentAvatar }) => {
  const [selectedStyle, setSelectedStyle] = useState('avataaars');
  const [clickedAvatar, setClickedAvatar] = useState(null);
  const styles = ['avataaars', 'bottts', 'pixel-art', 'adventurer', 'big-smile'];

  const generatePreview = (style, seed) => {
    return `https://api.dicebear.com/7.x/${style}/svg?seed=${seed}`;
  };

  const handleAvatarSelect = (avatarUrl) => {
    setClickedAvatar(avatarUrl);
    onSelect(avatarUrl);
  };

  return (
    <div className="avatar-selector">
      <div className="style-selector mb-4">
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
          Style d'avatar :
        </label>
        <select 
          className="w-full p-2 border rounded-md bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100"
          value={selectedStyle}
          onChange={(e) => {
            setSelectedStyle(e.target.value);
            setClickedAvatar(null);
          }}
        >
          {styles.map((style) => (
            <option key={style} value={style}>
              {style.charAt(0).toUpperCase() + style.slice(1)}
            </option>
          ))}
        </select>
      </div>
      
      <div className="avatars-grid">
        {[...Array(6)].map((_, index) => {
          const avatarUrl = generatePreview(selectedStyle, `option-${index}`);
          const isSelected = clickedAvatar === avatarUrl;
          
          return (
            <div 
              key={index}
              className={`${isSelected ? 'selected' : ''}`}
              onClick={() => handleAvatarSelect(avatarUrl)}
            >
              <img 
                src={avatarUrl} 
                alt={`Avatar option ${index + 1}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AvatarSelector;
