import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';
import { FaPlus } from 'react-icons/fa';
import heroEssentialApps from '../assets/hero-essential-apps.jpg';
import './EssentialApps.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';
import NouvDiscModal from '../components/Forum/NouvDiscModal';

const EssentialApps = () => {
    const [discussions, setDiscussions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchDiscussions = async () => {
            try {
                const discussionsRef = collection(db, 'essential-apps-discussions');
                const q = query(discussionsRef, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                
                const discussionsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    pinned: doc.data().pinned || false,
                    createdAt: doc.data().createdAt || { seconds: 0 }
                }));

                const sortedDiscussions = discussionsData.sort((a, b) => {
                    if (a.pinned && !b.pinned) return -1;
                    if (!a.pinned && b.pinned) return 1;
                    return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
                });

                setDiscussions(sortedDiscussions);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching discussions:", error);
                setLoading(false);
            }
        };

        fetchDiscussions();
    }, []);

    const handleDiscussionUpdate = async () => {
        const discussionsRef = collection(db, 'essential-apps-discussions');
        const querySnapshot = await getDocs(discussionsRef);
        
        const discussionsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            pinned: doc.data().pinned || false,
            createdAt: doc.data().createdAt || { seconds: 0 }
        }));

        const sortedDiscussions = discussionsData.sort((a, b) => {
            if (a.pinned && !b.pinned) return -1;
            if (!a.pinned && b.pinned) return 1;
            return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
        });

        setDiscussions(sortedDiscussions);
    };

    return (
        <main className="essential-apps-page">
            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Applications Indispensables</h1>
                        <p>Découvrez et partagez les applications mobiles essentielles pour votre quotidien</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroEssentialApps}
                    alt="Collection d'applications essentielles pour la productivité et le divertissement sur mobile" 
                />
            </section>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="essential-apps-discussions"
                                onReply={handleDiscussionUpdate}
                            />
                        ))}
                    </div>
                )}
            </section>

            {currentUser && (
                <>
                    <button 
                        className="new-discussion-button"
                        onClick={() => setShowModal(true)}
                        title="Nouvelle discussion"
                    >
                        <FaPlus size={24} />
                    </button>

                    {showModal && (
                        <NouvDiscModal
                            isOpen={showModal}
                            onClose={() => setShowModal(false)}
                            forumId="essential-apps-discussions"
                            onDiscussionUpdate={handleDiscussionUpdate}
                        />
                    )}
                </>
            )}
        </main>
    );
};

export default EssentialApps;
