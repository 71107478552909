import React, { createContext, useState, useContext, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useUserProfile } from '../hooks/useUserProfile';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  
  // Utiliser useUserProfile pour obtenir les informations complètes du profil
  const { userProfile } = useUserProfile(currentUser?.uid);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        // Forcer une relecture des données utilisateur
        await user.reload();
        // Si l'utilisateur est connecté, mettre à jour ses informations avec le profil complet
        setCurrentUser({
          ...user,
          displayName: userProfile?.displayName || user.displayName,
          photoURL: userProfile?.photoURL || user.photoURL,
          role: userProfile?.role,
          badge: userProfile?.badge
        });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [auth, userProfile]);

  const value = {
    currentUser,
    isAuthenticated: !!currentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
