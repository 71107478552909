import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';
import { searchService } from '../services/search/SearchService';

// Table de correspondance pour les IDs de section
const sectionIdMap = {
    'electric-mobility': 'mobilite-electrique',
    'quantum-computing': 'informatique-quantique'
};

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const searchRef = useRef(null);
    const navigate = useNavigate();
    const MIN_SEARCH_LENGTH = 2;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSearchChange = async (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        if (value.trim().length < MIN_SEARCH_LENGTH) {
            setSuggestions([]);
            setIsDropdownVisible(false);
            return;
        }

        setIsLoading(true);
        try {
            const results = await searchService.search(value.trim());
            setSuggestions(results);
            setIsDropdownVisible(results.length > 0);
        } catch (error) {
            console.error('Erreur lors de la recherche:', error);
            setSuggestions([]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchTerm('');
        setSuggestions([]);
        setIsDropdownVisible(false);

        // Extraire le chemin de la page et la section
        const { page } = suggestion;

        // Extraire l'ID de section du path s'il existe
        const hashIndex = page.indexOf('#');
        if (hashIndex !== -1) {
            const originalSectionId = page.slice(hashIndex + 1);
            // Utiliser l'ID mappé si disponible, sinon utiliser l'ID original
            const sectionId = sectionIdMap[originalSectionId] || originalSectionId;
            const basePath = page.slice(0, hashIndex);
            
            // Construire le nouveau chemin avec l'ID correct
            const newPath = `${basePath}#${sectionId}`;
            navigate(newPath);

            // Attendre que la page soit chargée et tenter le défilement plusieurs fois
            const maxAttempts = 5;
            let attempts = 0;

            const tryScroll = () => {
                const sectionElement = document.getElementById(sectionId);
                if (sectionElement) {
                    sectionElement.scrollIntoView({ behavior: 'smooth' });
                    const navbarHeight = 80;
                    window.scrollBy(0, -navbarHeight);
                } else if (attempts < maxAttempts) {
                    attempts++;
                    setTimeout(tryScroll, 200);
                }
            };

            setTimeout(tryScroll, 100);
        } else {
            // Si pas de section spécifique, naviguer simplement vers la page
            navigate(page);
        }
    };

    return (
        <div className="search-container" ref={searchRef}>
            <div className="search-input-wrapper">
                <input
                    type="text"
                    className="search-input"
                    placeholder="Rechercher (minimum 2 caractères)..."
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => {
                        if (searchTerm.trim().length >= MIN_SEARCH_LENGTH) {
                            setIsDropdownVisible(suggestions.length > 0);
                        }
                    }}
                />
                {isLoading && <div className="search-spinner"></div>}
            </div>
            {isDropdownVisible && (
                <div className="search-dropdown">
                    {suggestions.length === 0 ? (
                        <div className="no-results">Aucun résultat trouvé</div>
                    ) : (
                        suggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className="suggestion-item"
                                onClick={() => handleSuggestionClick(suggestion)}
                            >
                                <span className="suggestion-keyword">{suggestion.keyword}</span>
                                <span className="suggestion-section">{suggestion.section}</span>
                            </div>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchBar;
