import React, { useState } from 'react';
import { updateProfile, updateEmail, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, db, storage } from '../firebase';
import AvatarSelector from './AvatarSelector';

const ProfileSettings = ({ onClose }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(auth.currentUser?.photoURL);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [bio, setBio] = useState('');
  const [activeTab, setActiveTab] = useState('avatar');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showUpload, setShowUpload] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('Veuillez sélectionner une image');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        setError('L\'image ne doit pas dépasser 5MB');
        return;
      }
      setUploadedFile(file);
      setSelectedAvatar(null);
      setError('');
    }
  };

  const handleAvatarUpdate = async () => {
    if (!selectedAvatar && !uploadedFile) return;
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      let photoURL = selectedAvatar;

      if (uploadedFile) {
        const fileRef = ref(storage, `avatars/${auth.currentUser.uid}/${Date.now()}_${uploadedFile.name}`);
        await uploadBytes(fileRef, uploadedFile);
        photoURL = await getDownloadURL(fileRef);
      }

      // Mise à jour dans Firebase Auth
      await updateProfile(auth.currentUser, {
        photoURL: photoURL
      });

      // Mise à jour du document utilisateur dans Firestore
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        photoURL: photoURL
      });

      // Mise à jour de tous les posts de l'utilisateur
      const postsRef = collection(db, 'posts');
      const postsQuery = query(postsRef, where('userId', '==', auth.currentUser.uid));
      const postsSnapshot = await getDocs(postsQuery);
      
      const postUpdates = postsSnapshot.docs.map(doc => 
        updateDoc(doc.ref, {
          userPhotoURL: photoURL
        })
      );

      // Mise à jour de tous les commentaires de l'utilisateur
      const commentsRef = collection(db, 'comments');
      const commentsQuery = query(commentsRef, where('userId', '==', auth.currentUser.uid));
      const commentsSnapshot = await getDocs(commentsQuery);
      
      const commentUpdates = commentsSnapshot.docs.map(doc => 
        updateDoc(doc.ref, {
          userPhotoURL: photoURL
        })
      );

      // Exécuter toutes les mises à jour en parallèle
      await Promise.all([...postUpdates, ...commentUpdates]);

      setSuccess('Avatar mis à jour avec succès !');
      setTimeout(() => onClose(), 1500);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'avatar:', error);
      setError('Une erreur est survenue lors de la mise à jour de l\'avatar.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailUpdate = async () => {
    if (!newEmail || !currentPassword) return;
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updateEmail(auth.currentUser, newEmail);
      
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        email: newEmail
      });

      setSuccess('Email mis à jour avec succès !');
      setNewEmail('');
      setCurrentPassword('');
      setTimeout(() => onClose(), 1500);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('Mot de passe incorrect');
      } else if (error.code === 'auth/invalid-email') {
        setError('Format d\'email invalide');
      } else {
        setError('Erreur lors de la mise à jour de l\'email');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordUpdate = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) return;
    if (newPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas');
      return;
    }
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);
      await updatePassword(auth.currentUser, newPassword);
      
      setSuccess('Mot de passe mis à jour avec succès !');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setTimeout(() => onClose(), 1500);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('Ancien mot de passe incorrect');
      } else {
        setError('Erreur lors de la mise à jour du mot de passe');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBioUpdate = async () => {
    if (!bio) return;
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      const userRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userRef, {
        bio: bio
      });

      setSuccess('Bio mise à jour avec succès !');
      setTimeout(() => onClose(), 1500);
    } catch (error) {
      setError('Erreur lors de la mise à jour de la bio');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content profile-settings">
        <button className="close-modal" onClick={onClose}>&times;</button>
        <h2>Paramètres du profil</h2>

        <div className="settings-tabs">
          <button 
            className={`tab-btn ${activeTab === 'avatar' ? 'active' : ''}`}
            onClick={() => setActiveTab('avatar')}
          >
            Avatar
          </button>
          <button 
            className={`tab-btn ${activeTab === 'email' ? 'active' : ''}`}
            onClick={() => setActiveTab('email')}
          >
            Email
          </button>
          <button 
            className={`tab-btn ${activeTab === 'password' ? 'active' : ''}`}
            onClick={() => setActiveTab('password')}
          >
            Mot de passe
          </button>
          <button 
            className={`tab-btn ${activeTab === 'bio' ? 'active' : ''}`}
            onClick={() => setActiveTab('bio')}
          >
            Bio
          </button>
        </div>
        
        {error && <div className="alert error">{error}</div>}
        {success && <div className="alert success">{success}</div>}

        <div className="settings-content">
          {activeTab === 'avatar' && (
            <div className="settings-section">
              <div className="space-y-4">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">Avatar</h3>
                
                {/* Bouton pour afficher la section d'upload */}
                <button
                  onClick={() => setShowUpload(!showUpload)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  {showUpload ? 'Masquer l\'upload' : 'Uploader un Avatar'}
                </button>

                {/* Section d'upload conditionnelle */}
                {showUpload && (
                  <div className="space-y-2 mt-4">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Uploader une image
                    </label>
                    <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-lg hover:border-gray-400 dark:hover:border-gray-500 transition-colors cursor-pointer">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600 dark:text-gray-400">
                          <label className="relative cursor-pointer rounded-md font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500 focus-within:outline-none">
                            <span>Uploader un fichier</span>
                            <input
                              type="file"
                              className="sr-only"
                              accept="image/*"
                              onChange={handleFileChange}
                            />
                          </label>
                          <p className="pl-1">ou glisser et déposer</p>
                        </div>
                        <p className="text-xs text-gray-500 dark:text-gray-400">
                          PNG, JPG, GIF jusqu'à 5MB
                        </p>
                      </div>
                    </div>
                    {uploadedFile && (
                      <div className="mt-2 flex items-center space-x-2">
                        <img
                          src={URL.createObjectURL(uploadedFile)}
                          alt="Aperçu"
                          className="h-12 w-12 rounded-full object-cover"
                        />
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {uploadedFile.name}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {/* Séparateur */}
                <div className="relative my-6">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300 dark:border-gray-600" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-400">
                      Ou choisir un avatar prédéfini
                    </span>
                  </div>
                </div>

                {/* Avatar Selector */}
                <AvatarSelector
                  onSelect={(avatarUrl) => {
                    setSelectedAvatar(avatarUrl);
                    setUploadedFile(null);
                    setShowUpload(false); // Masquer la section d'upload si un avatar prédéfini est sélectionné
                  }}
                  currentAvatar={selectedAvatar}
                />
              </div>
              <button 
                className="submit-btn"
                onClick={handleAvatarUpdate}
                disabled={isLoading || (!selectedAvatar && !uploadedFile)}
              >
                {isLoading ? 'Mise à jour...' : 'Mettre à jour l\'avatar'}
              </button>
            </div>
          )}

          {activeTab === 'email' && (
            <div className="settings-section">
              <div className="form-group">
                <label>Nouvel email</label>
                <input
                  type="email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  placeholder="Entrez votre nouvel email"
                />
              </div>
              <div className="form-group">
                <label>Mot de passe actuel</label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Entrez votre mot de passe actuel"
                />
              </div>
              <button 
                className="submit-btn"
                onClick={handleEmailUpdate}
                disabled={isLoading || !newEmail || !currentPassword}
              >
                {isLoading ? 'Mise à jour...' : 'Mettre à jour l\'email'}
              </button>
            </div>
          )}

          {activeTab === 'password' && (
            <div className="settings-section">
              <div className="form-group">
                <label>Mot de passe actuel</label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Entrez votre mot de passe actuel"
                />
              </div>
              <div className="form-group">
                <label>Nouveau mot de passe</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="Entrez votre nouveau mot de passe"
                />
              </div>
              <div className="form-group">
                <label>Confirmer le mot de passe</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirmez votre nouveau mot de passe"
                />
              </div>
              <button 
                className="submit-btn"
                onClick={handlePasswordUpdate}
                disabled={isLoading || !currentPassword || !newPassword || !confirmPassword}
              >
                {isLoading ? 'Mise à jour...' : 'Mettre à jour le mot de passe'}
              </button>
            </div>
          )}

          {activeTab === 'bio' && (
            <div className="settings-section">
              <div className="form-group">
                <label>Votre bio</label>
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  placeholder="Parlez-nous un peu de vous..."
                  rows="4"
                />
              </div>
              <button 
                className="submit-btn"
                onClick={handleBioUpdate}
                disabled={isLoading || !bio}
              >
                {isLoading ? 'Mise à jour...' : 'Mettre à jour la bio'}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
