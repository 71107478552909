import { db } from '../../firebase';
import { collection, query, where, getDocs, writeBatch, doc } from 'firebase/firestore';
// eslint-disable-next-line no-unused-vars
import { deleteDoc } from 'firebase/firestore';

class SearchService {
    constructor() {
        this.searchCollection = collection(db, 'searchIndex');
        this.initialized = false;
    }

    // Vérifier si l'index existe déjà
    async checkIndexExists() {
        try {
            const snapshot = await getDocs(this.searchCollection);
            return !snapshot.empty;
        } catch (error) {
            console.error('Erreur lors de la vérification de l\'index:', error);
            return false;
        }
    }

    // Rechercher du contenu
    async search(searchTerm) {
        if (!searchTerm || searchTerm.length < 2) return [];

        try {
            const querySnapshot = await getDocs(this.searchCollection);
            const resultsMap = new Map();
            const searchTermLower = searchTerm.toLowerCase();

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                const searchableTerms = data.searchableTerms || [];
                
                // Vérifie si un des termes commence par la recherche
                const matchingTerms = searchableTerms.filter(term => 
                    term.toLowerCase().includes(searchTermLower)
                );

                if (matchingTerms.length > 0) {
                    // Utiliser la combinaison titre + section comme clé unique
                    const key = `${data.title}-${data.section}`;
                    if (!resultsMap.has(key)) {
                        resultsMap.set(key, {
                            id: doc.id,
                            keyword: data.title,
                            section: data.section,
                            page: data.path,
                            type: data.type,
                            matchCount: matchingTerms.length
                        });
                    }
                }
            });

            return Array.from(resultsMap.values())
                .sort((a, b) => b.matchCount - a.matchCount);
        } catch (error) {
            throw new Error('Erreur lors de la recherche. Veuillez réessayer.');
        }
    }

    // Nettoyer l'index existant
    async cleanIndex() {
        try {
            const snapshot = await getDocs(this.searchCollection);
            const batch = writeBatch(db);
            
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });

            await batch.commit();
            return true;
        } catch (error) {
            console.error('Erreur lors du nettoyage de l\'index:', error);
            return false;
        }
    }

    // Ajouter du contenu à l'index de recherche
    async addToIndex(content) {
        try {
            // Vérifier si l'entrée existe déjà
            const q = query(
                this.searchCollection,
                where('title', '==', content.title),
                where('section', '==', content.section)
            );
            const snapshot = await getDocs(q);

            // Si l'entrée existe déjà, la supprimer
            if (!snapshot.empty) {
                const batch = writeBatch(db);
                snapshot.docs.forEach((doc) => {
                    batch.delete(doc.ref);
                });
                await batch.commit();
            }

            // Ajouter la nouvelle entrée
            const docRef = doc(this.searchCollection);
            const batch = writeBatch(db);

            const searchableTerms = this.generateSearchableTerms(content);
            
            batch.set(docRef, {
                title: content.title,
                path: content.path,
                section: content.section,
                type: content.type,
                keywords: content.keywords,
                searchableTerms: searchableTerms,
                lastUpdated: new Date().toISOString()
            });

            await batch.commit();
            return true;
        } catch (error) {
            console.error('Erreur lors de l\'ajout à l\'index:', error);
            throw new Error('Erreur lors de l\'ajout à l\'index. Veuillez réessayer.');
        }
    }

    // Réinitialiser l'index de recherche
    async resetIndex(contents) {
        try {
            // Nettoyer l'index existant
            await this.cleanIndex();

            // Ajouter les nouveaux documents
            for (const content of contents) {
                await this.addToIndex(content);
            }

            this.initialized = true;
            return true;
        } catch (error) {
            console.error('Erreur lors de la réinitialisation de l\'index:', error);
            throw new Error('Erreur lors de la réinitialisation de l\'index. Veuillez réessayer.');
        }
    }

    // Générer les termes de recherche
    generateSearchableTerms(content) {
        const terms = new Set();

        // Ajouter le titre et ses mots individuels
        const titleWords = content.title.toLowerCase().split(' ');
        titleWords.forEach(word => {
            if (word.length >= 2) terms.add(word);
        });
        terms.add(content.title.toLowerCase());

        // Ajouter les mots-clés et leurs mots individuels
        content.keywords.forEach(keyword => {
            const keywordWords = keyword.toLowerCase().split(' ');
            keywordWords.forEach(word => {
                if (word.length >= 2) terms.add(word);
            });
            terms.add(keyword.toLowerCase());
        });

        return Array.from(terms);
    }
}

export const searchService = new SearchService();
