import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

export const techGadgetsSearchData = [
  // Section Smartphones
  {
    title: "iPhone",
    path: "/tech-gadgets#iphone",
    section: "tech-gadgets",
    type: "article",
    keywords: ["iPhone", "Apple", "iOS", "smartphone"],
    searchableTerms: [
      "iPhone",
      "Apple",
      "iOS",
      "smartphone",
      "téléphone Apple",
      "mobile Apple",
      "Face ID",
      "iPhone Pro",
      "iPhone Max",
      "iPhone Mini"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Samsung Galaxy",
    path: "/tech-gadgets#samsung",
    section: "tech-gadgets",
    type: "article",
    keywords: ["Samsung", "Galaxy", "Android", "smartphone"],
    searchableTerms: [
      "Samsung Galaxy",
      "Android",
      "smartphone Samsung",
      "téléphone Samsung",
      "Galaxy S",
      "Galaxy Note",
      "Galaxy Fold",
      "Galaxy Flip",
      "One UI",
      "mobile Samsung"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Xiaomi",
    path: "/tech-gadgets#xiaomi",
    section: "tech-gadgets",
    type: "article",
    keywords: ["Xiaomi", "MIUI", "Android", "smartphone"],
    searchableTerms: [
      "Xiaomi",
      "MIUI",
      "Android",
      "smartphone Xiaomi",
      "téléphone Xiaomi",
      "Redmi",
      "POCO",
      "Mi",
      "mobile Xiaomi",
      "Xiaomi global"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Google Pixel",
    path: "/tech-gadgets#pixel",
    section: "tech-gadgets",
    type: "article",
    keywords: ["Google", "Pixel", "Android", "smartphone"],
    searchableTerms: [
      "Google Pixel",
      "Android stock",
      "smartphone Google",
      "téléphone Pixel",
      "Pixel Pro",
      "Pixel A",
      "appareil photo Pixel",
      "Google Photos",
      "Assistant Google",
      "mobile Google"
    ],
    lastUpdated: new Date().toISOString()
  },

  // Section Tablettes
  {
    title: "iPad",
    path: "/tech-gadgets#ipad",
    section: "tech-gadgets",
    type: "article",
    keywords: ["iPad", "Apple", "tablette", "iPadOS"],
    searchableTerms: [
      "iPad",
      "Apple",
      "tablette Apple",
      "iPadOS",
      "iPad Pro",
      "iPad Air",
      "iPad Mini",
      "Apple Pencil",
      "Magic Keyboard",
      "tablette tactile"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Samsung Galaxy Tab",
    path: "/tech-gadgets#galaxy-tab",
    section: "tech-gadgets",
    type: "article",
    keywords: ["Samsung", "Galaxy Tab", "tablette", "Android"],
    searchableTerms: [
      "Samsung Galaxy Tab",
      "tablette Samsung",
      "Android tablet",
      "Galaxy Tab S",
      "S Pen",
      "DeX",
      "tablette Android",
      "Galaxy Tab A",
      "Book Cover",
      "tablette tactile Samsung"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Microsoft Surface",
    path: "/tech-gadgets#surface",
    section: "tech-gadgets",
    type: "article",
    keywords: ["Surface", "Microsoft", "tablette", "Windows"],
    searchableTerms: [
      "Microsoft Surface",
      "Surface Pro",
      "Surface Go",
      "Windows tablet",
      "tablette Windows",
      "Surface Pen",
      "Type Cover",
      "2-en-1",
      "tablette PC",
      "Surface Book"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Tablettes Android",
    path: "/tech-gadgets#android-tablets",
    section: "tech-gadgets",
    type: "article",
    keywords: ["tablettes Android", "Android", "tablette"],
    searchableTerms: [
      "tablettes Android",
      "Android tablet",
      "tablette tactile",
      "Lenovo Tab",
      "Huawei MatePad",
      "Amazon Fire",
      "tablette pas cher",
      "tablette multimédia",
      "tablette éducative",
      "tablette familiale"
    ],
    lastUpdated: new Date().toISOString()
  },

  // Section Ordinateurs
  {
    title: "MacBook",
    path: "/tech-gadgets#macbook",
    section: "tech-gadgets",
    type: "article",
    keywords: ["MacBook", "Apple", "laptop", "ordinateur portable"],
    searchableTerms: [
      "MacBook",
      "MacBook Pro",
      "MacBook Air",
      "Apple Silicon",
      "M1",
      "M2",
      "macOS",
      "ordinateur Apple",
      "laptop Apple",
      "portable Mac"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "PC Portable Gaming",
    path: "/tech-gadgets#gaming-laptop",
    section: "tech-gadgets",
    type: "article",
    keywords: ["PC gaming", "laptop gaming", "ordinateur portable gamer"],
    searchableTerms: [
      "PC portable gaming",
      "laptop gamer",
      "ordinateur gaming",
      "ASUS ROG",
      "MSI Gaming",
      "Razer Blade",
      "Alienware",
      "RTX",
      "carte graphique gaming",
      "performance gaming"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "PC Portable Pro",
    path: "/tech-gadgets#pro-laptop",
    section: "tech-gadgets",
    type: "article",
    keywords: ["PC pro", "laptop professionnel", "ordinateur portable pro"],
    searchableTerms: [
      "PC portable professionnel",
      "laptop pro",
      "ThinkPad",
      "Dell XPS",
      "HP EliteBook",
      "ordinateur entreprise",
      "station de travail mobile",
      "ultrabook",
      "portable business",
      "workstation"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "PC Bureau",
    path: "/tech-gadgets#desktop",
    section: "tech-gadgets",
    type: "article",
    keywords: ["PC bureau", "desktop", "ordinateur fixe"],
    searchableTerms: [
      "PC bureau",
      "ordinateur fixe",
      "desktop",
      "tour PC",
      "PC sur mesure",
      "PC assemblé",
      "PC gamer fixe",
      "station de travail",
      "PC tout-en-un",
      "iMac"
    ],
    lastUpdated: new Date().toISOString()
  },

  // Section Accessoires
  {
    title: "Écouteurs Sans Fil",
    path: "/tech-gadgets#wireless-earbuds",
    section: "tech-gadgets",
    type: "article",
    keywords: ["écouteurs", "wireless", "true wireless", "earbuds"],
    searchableTerms: [
      "écouteurs sans fil",
      "true wireless",
      "AirPods",
      "Galaxy Buds",
      "Sony WF",
      "écouteurs Bluetooth",
      "intra-auriculaires",
      "réduction de bruit",
      "casque sport",
      "earbuds"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Casques Audio",
    path: "/tech-gadgets#headphones",
    section: "tech-gadgets",
    type: "article",
    keywords: ["casque audio", "headphones", "casque sans fil"],
    searchableTerms: [
      "casque audio",
      "casque Bluetooth",
      "casque sans fil",
      "AirPods Max",
      "Sony WH",
      "Bose",
      "casque à réduction de bruit",
      "casque gaming",
      "casque studio",
      "headphones"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Montres Connectées",
    path: "/tech-gadgets#smartwatch",
    section: "tech-gadgets",
    type: "article",
    keywords: ["montre connectée", "smartwatch", "tracker"],
    searchableTerms: [
      "montre connectée",
      "smartwatch",
      "Apple Watch",
      "Galaxy Watch",
      "Fitbit",
      "Garmin",
      "tracker d'activité",
      "montre sport",
      "montre santé",
      "bracelet connecté"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Accessoires Gaming",
    path: "/tech-gadgets#gaming-accessories",
    section: "tech-gadgets",
    type: "article",
    keywords: ["accessoires gaming", "gaming", "périphériques"],
    searchableTerms: [
      "accessoires gaming",
      "souris gamer",
      "clavier mécanique",
      "casque gaming",
      "manette",
      "tapis souris",
      "streaming",
      "webcam gaming",
      "micro gaming",
      "setup gaming"
    ],
    lastUpdated: new Date().toISOString()
  }
];

// Fonction pour initialiser les données de recherche pour la section Tech & Gadgets
export const initTechGadgetsSearch = async () => {
  try {
    for (const item of techGadgetsSearchData) {
      await addDoc(collection(db, 'searchIndex'), item);
    }
    return true;
  } catch (error) {
    return false;
  }
};
