import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import AG from '../assets/AG.png';

// Fonction générique pour obtenir un avatar par défaut
// On utilise pixel-art comme style par défaut, mais ça pourrait être n'importe quel style
const getDefaultAvatar = (userId, style = 'pixel-art') => `https://api.dicebear.com/7.x/${style}/svg?seed=${userId}`;

const defaultProfiles = {
    // Sophie Martin - utilise pixel-art
    'sophie_martin': {
        displayName: 'Sophie Martin',
        photoURL: getDefaultAvatar('sophie_martin', 'pixel-art'),
        avatarStyle: 'pixel-art'
    },
    // Thomas Dubois - utilise avataaars
    'thomas_dubois': {
        displayName: 'Thomas Dubois',
        photoURL: getDefaultAvatar('thomas_dubois', 'avataaars'),
        avatarStyle: 'avataaars'
    },
    // Nicolas Roux - utilise pixel-art
    'nicolas_roux': {
        displayName: 'Nicolas Roux',
        photoURL: getDefaultAvatar('nicolas_roux', 'pixel-art'),
        avatarStyle: 'pixel-art'
    },
    // Julie Petit - utilise lorelei
    'julie_petit': {
        displayName: 'Julie Petit',
        photoURL: getDefaultAvatar('julie_petit', 'lorelei'),
        avatarStyle: 'lorelei'
    },
    // Admin AzureTM
    'et0ghHazwWPz053JZtaw0Xt8Hox2': {
        displayName: 'AzureTM',
        role: 'admin',
        badge: { type: 'admin', label: 'Admin' },
        photoURL: AG
    },
    'Np81OfHPMpfbAFoynOLMnduCvd42': {
        displayName: 'AzureTM',
        role: 'admin',
        badge: { type: 'admin', label: 'Admin' },
        photoURL: AG
    }
};

// Map des noms d'affichage vers les IDs
const displayNameToId = {
    'Sophie Martin': 'sophie_martin',
    'Thomas Dubois': 'thomas_dubois',
    'Nicolas Roux': 'nicolas_roux',
    'Julie Petit': 'julie_petit'
};

export const getUserIdByDisplayName = (displayName) => {
    return displayNameToId[displayName] || null;
};

export const useUserProfile = (userId) => {
    const [userProfile, setUserProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (!userId) {
                setLoading(false);
                return;
            }

            try {
                // 1. Vérifier d'abord si c'est un profil par défaut
                if (defaultProfiles[userId]) {
                    const profile = defaultProfiles[userId];
                    // Si pas de photoURL, en générer un avec le style spécifié ou pixel-art par défaut
                    if (!profile.photoURL) {
                        profile.photoURL = getDefaultAvatar(userId, profile.avatarStyle || 'pixel-art');
                    }
                    setUserProfile(profile);
                    setLoading(false);
                    return;
                }

                // 2. Sinon chercher dans Firestore
                const userDoc = await getDoc(doc(db, 'users', userId));
                if (userDoc.exists()) {
                    const data = userDoc.data();
                    // Si pas de photoURL, utiliser le style spécifié ou pixel-art par défaut
                    if (!data.photoURL) {
                        data.photoURL = getDefaultAvatar(userId, data.avatarStyle || 'pixel-art');
                    }
                    setUserProfile(data);
                } else {
                    // 3. Si le profil n'existe pas, créer un profil par défaut simple
                    setUserProfile({
                        displayName: 'Utilisateur',
                        photoURL: getDefaultAvatar(userId, 'pixel-art'),
                        avatarStyle: 'pixel-art'
                    });
                }
            } catch (err) {
                setError(err);
                console.error('Error fetching user profile:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserProfile();
    }, [userId]);

    return { userProfile, loading, error };
};
