import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

export const devSearchData = [
  // Section Intelligence Artificielle
  {
    title: "Machine Learning",
    path: "/developpement#machine-learning",
    section: "developpement",
    type: "article",
    keywords: ["machine learning", "apprentissage automatique", "ML", "IA"],
    searchableTerms: [
      "machine learning",
      "apprentissage automatique",
      "ML",
      "intelligence artificielle",
      "algorithmes d'apprentissage",
      "deep learning",
      "données",
      "prédiction",
      "classification",
      "régression"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Deep Learning",
    path: "/developpement#deep-learning",
    section: "developpement",
    type: "article",
    keywords: ["deep learning", "réseaux de neurones", "apprentissage profond", "neural networks"],
    searchableTerms: [
      "deep learning",
      "réseaux de neurones",
      "apprentissage profond",
      "neural networks",
      "CNN",
      "RNN",
      "intelligence artificielle",
      "couches neuronales",
      "backpropagation",
      "tensorflow",
      "pytorch"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Traitement du Langage Naturel",
    path: "/developpement#nlp",
    section: "developpement",
    type: "article",
    keywords: ["NLP", "traitement langage naturel", "linguistique", "text mining"],
    searchableTerms: [
      "NLP",
      "traitement du langage naturel",
      "linguistique computationnelle",
      "text mining",
      "analyse de texte",
      "chatbot",
      "reconnaissance vocale",
      "GPT",
      "BERT",
      "transformers"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Computer Vision",
    path: "/developpement#computer-vision",
    section: "developpement",
    type: "article",
    keywords: ["vision par ordinateur", "traitement d'image", "reconnaissance visuelle"],
    searchableTerms: [
      "computer vision",
      "vision par ordinateur",
      "traitement d'image",
      "reconnaissance visuelle",
      "détection d'objets",
      "reconnaissance faciale",
      "OpenCV",
      "CNN",
      "segmentation d'image",
      "deep learning vision"
    ],
    lastUpdated: new Date().toISOString()
  },

  // Section Réalité Virtuelle
  {
    title: "VR Gaming",
    path: "/developpement#vr-gaming",
    section: "developpement",
    type: "article",
    keywords: ["VR", "réalité virtuelle", "jeux", "gaming"],
    searchableTerms: [
      "VR gaming",
      "réalité virtuelle",
      "jeux VR",
      "développement jeux VR",
      "Unity VR",
      "Unreal Engine VR",
      "Oculus",
      "SteamVR",
      "immersion",
      "contrôleurs VR"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Formation VR",
    path: "/developpement#formation-vr",
    section: "developpement",
    type: "article",
    keywords: ["formation VR", "apprentissage virtuel", "simulation", "entraînement"],
    searchableTerms: [
      "formation VR",
      "apprentissage virtuel",
      "simulation VR",
      "entraînement virtuel",
      "e-learning VR",
      "formation professionnelle",
      "réalité virtuelle éducative",
      "serious games",
      "simulation métier",
      "formation immersive"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Réalité Augmentée",
    path: "/developpement#realite-augmentee",
    section: "developpement",
    type: "article",
    keywords: ["AR", "réalité augmentée", "mixed reality", "overlay"],
    searchableTerms: [
      "réalité augmentée",
      "AR",
      "mixed reality",
      "overlay",
      "ARKit",
      "ARCore",
      "développement AR",
      "applications AR",
      "visualisation 3D",
      "interaction AR"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Métavers",
    path: "/developpement#metavers",
    section: "developpement",
    type: "article",
    keywords: ["métavers", "monde virtuel", "virtual world", "social VR"],
    searchableTerms: [
      "métavers",
      "monde virtuel",
      "virtual world",
      "social VR",
      "environnement virtuel",
      "avatar",
      "interaction sociale VR",
      "économie virtuelle",
      "NFT",
      "web3"
    ],
    lastUpdated: new Date().toISOString()
  },

  // Section Informatique Quantique
  {
    title: "Algorithmes Quantiques",
    path: "/developpement#algorithmes-quantiques",
    section: "developpement",
    type: "article",
    keywords: ["algorithmes quantiques", "quantum computing", "qubits", "calcul quantique"],
    searchableTerms: [
      "algorithmes quantiques",
      "quantum computing",
      "qubits",
      "calcul quantique",
      "Shor",
      "Grover",
      "cryptographie quantique",
      "optimisation quantique",
      "superposition",
      "intrication"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Hardware Quantique",
    path: "/developpement#hardware-quantique",
    section: "developpement",
    type: "article",
    keywords: ["hardware quantique", "ordinateur quantique", "processeur quantique"],
    searchableTerms: [
      "hardware quantique",
      "ordinateur quantique",
      "processeur quantique",
      "IBM Quantum",
      "Google Quantum",
      "D-Wave",
      "qubits supraconducteurs",
      "ions piégés",
      "photons",
      "correction d'erreur quantique"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Applications Quantiques",
    path: "/developpement#applications",
    section: "developpement",
    type: "article",
    keywords: ["applications quantiques", "use cases", "simulation quantique"],
    searchableTerms: [
      "applications quantiques",
      "use cases quantiques",
      "simulation quantique",
      "finance quantique",
      "chimie quantique",
      "optimisation",
      "machine learning quantique",
      "Qiskit",
      "Cirq",
      "cas d'usage"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Cryptographie Quantique",
    path: "/developpement#cryptographie",
    section: "developpement",
    type: "article",
    keywords: ["cryptographie quantique", "QKD", "sécurité quantique"],
    searchableTerms: [
      "cryptographie quantique",
      "QKD",
      "distribution de clé quantique",
      "sécurité post-quantique",
      "chiffrement quantique",
      "protocole BB84",
      "intrication",
      "non-clonage",
      "communication quantique",
      "quantum safe"
    ],
    lastUpdated: new Date().toISOString()
  },

  // Section Blockchain
  {
    title: "Smart Contracts",
    path: "/developpement#smart-contracts",
    section: "developpement",
    type: "article",
    keywords: ["smart contracts", "contrats intelligents", "blockchain", "Ethereum"],
    searchableTerms: [
      "smart contracts",
      "contrats intelligents",
      "Ethereum",
      "Solidity",
      "développement blockchain",
      "DApps",
      "web3",
      "tokens",
      "transactions automatisées",
      "décentralisation"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "DeFi",
    path: "/developpement#defi",
    section: "developpement",
    type: "article",
    keywords: ["DeFi", "finance décentralisée", "yield farming", "liquidity pools"],
    searchableTerms: [
      "DeFi",
      "finance décentralisée",
      "yield farming",
      "liquidity pools",
      "staking",
      "lending",
      "borrowing",
      "AMM",
      "tokens",
      "protocoles DeFi"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Bitcoin",
    path: "/developpement#bitcoin",
    section: "developpement",
    type: "article",
    keywords: ["Bitcoin", "BTC", "cryptomonnaie", "blockchain"],
    searchableTerms: [
      "Bitcoin",
      "BTC",
      "cryptomonnaie",
      "blockchain",
      "mining",
      "wallet",
      "transactions",
      "Lightning Network",
      "Satoshi Nakamoto",
      "proof of work"
    ],
    lastUpdated: new Date().toISOString()
  },
  {
    title: "Altcoins",
    path: "/developpement#altcoins",
    section: "developpement",
    type: "article",
    keywords: ["altcoins", "cryptomonnaies alternatives", "tokens", "blockchain"],
    searchableTerms: [
      "altcoins",
      "cryptomonnaies alternatives",
      "Ethereum",
      "tokens",
      "blockchain",
      "DeFi tokens",
      "NFT",
      "proof of stake",
      "smart chains",
      "cross-chain"
    ],
    lastUpdated: new Date().toISOString()
  }
];

// Fonction pour initialiser les données de recherche pour la section Développement
export const initDevSearch = async () => {
  try {
    for (const item of devSearchData) {
      await addDoc(collection(db, 'searchIndex'), item);
    }
    return true;
  } catch (error) {
    return false;
  }
};
