import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import heroTech from '../assets/hero-tech.jpg';
import trendsIcon from '../assets/icons/trends-icon.svg';
import innovationIcon from '../assets/icons/dev-icon.svg';
import gadgetsIcon from '../assets/icons/gadgets-icon.svg';
// eslint-disable-next-line no-unused-vars
import AuthModal from '../components/AuthModal';
import { useAuth } from '../contexts/AuthContext';

const Home = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const { currentUser } = useAuth();

  const handleDiscoverClick = () => {
    if (!currentUser) {
      setIsAuthModalOpen(true);
    } else {
      // Si l'utilisateur est déjà connecté, on le redirige vers les tendances tech
      window.location.href = '#trends';
    }
  };

  return (
    <main>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-left">
          <div className="hero-content">
            <h1><span style={{color: '#e63946'}}>Tech</span>Passion</h1>
            <p className="hero-subtitle">Votre guide dans l'univers de la technologie</p>
            <p className="hero-description">Explorez les dernières innovations, découvrez des tutoriels passionnants et restez à jour avec les tendances tech.</p>
            <button className="cta-button" onClick={handleDiscoverClick}>
              {currentUser ? "Explorer" : "Découvrir"}
            </button>
          </div>
        </div>
        <div className="hero-right">
          <img 
            className="hero-image"
            src={heroTech}
            alt="Page d'accueil présentant les dernières innovations technologiques et discussions de la communauté tech" 
          />
        </div>
      </section>

      {/* Tendances Tech */}
      <div className="category-section">
        <div className="section-header">
          <img src={trendsIcon} alt="Icône représentant les tendances technologiques avec un graphique croissant" className="section-icon" />
          <div className="section-text">
            <h2>Tendances Tech</h2>
            <p>Les technologies qui transforment notre quotidien</p>
          </div>
        </div>
        <div className="content-grid">
          <article className="tech-card">
            <Link to="/high-tech#smart-home">
              <div className="card-content">
                <h3>Smart Home</h3>
                <p>La maison connectée de demain</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/high-tech#mobilite-electrique">
              <div className="card-content">
                <h3>Mobilité Électrique</h3>
                <p>Transport durable et innovant</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/high-tech#cybersecurite">
              <div className="card-content">
                <h3>Cybersécurité</h3>
                <p>Protection des données nouvelle génération</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/high-tech#green-tech">
              <div className="card-content">
                <h3>Green Tech</h3>
                <p>Technologies pour un avenir durable</p>
              </div>
            </Link>
          </article>
        </div>
      </div>

      {/* Dernières Innovations */}
      <div className="category-section">
        <div className="section-header">
          <img src={innovationIcon} alt="Icône représentant le développement avec des symboles de code" className="section-icon" />
          <div className="section-text">
            <h2>Développement - Dernières Innovations</h2>
            <p>Découvrez les technologies qui façonnent notre avenir</p>
          </div>
        </div>
        <div className="content-grid">
          <article className="tech-card">
            <Link to="/developpement#intelligence-artificielle">
              <div className="card-content">
                <h3>Intelligence Artificielle</h3>
                <p>Les avancées majeures en IA et leurs applications</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/developpement#realite-virtuelle">
              <div className="card-content">
                <h3>Réalité Virtuelle</h3>
                <p>L'avenir de l'immersion numérique</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/developpement#informatique-quantique">
              <div className="card-content">
                <h3>Quantum Computing</h3>
                <p>La révolution du calcul quantique</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/developpement#blockchain">
              <div className="card-content">
                <h3>Blockchain</h3>
                <p>La technologie qui révolutionne les transactions</p>
              </div>
            </Link>
          </article>
        </div>
      </div>

      {/* Tech & Gadgets */}
      <div className="category-section">
        <div className="section-header">
          <img src={gadgetsIcon} alt="Icône représentant les gadgets et les technologies" className="section-icon" />
          <div className="section-text">
            <h2>Tech & Gadgets</h2>
            <p>Découvrez les derniers gadgets et innovations technologiques</p>
          </div>
        </div>
        <div className="content-grid">
          <article className="tech-card">
            <Link to="/tech-gadgets#smartphones">
              <div className="card-content">
                <h3>Smartphones</h3>
                <p>Les derniers modèles et innovations mobiles</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/tech-gadgets#ordinateurs">
              <div className="card-content">
                <h3>Ordinateurs</h3>
                <p>Guide complet sur le matériel informatique</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/tech-gadgets#iptv">
              <div className="card-content">
                <h3>IPTV</h3>
                <p>La télévision du futur est déjà là</p>
              </div>
            </Link>
          </article>
          <article className="tech-card">
            <Link to="/tech-gadgets#gadgets">
              <div className="card-content">
                <h3>Gadgets & Innovations</h3>
                <p>Les dernières innovations technologiques</p>
              </div>
            </Link>
          </article>
        </div>
      </div>

      {/* Modal d'authentification */}
      <AuthModal 
        isOpen={isAuthModalOpen} 
        onClose={() => setIsAuthModalOpen(false)} 
      />
    </main>
  );
};

export default Home;
