import React, { useState, useEffect } from 'react';
import { FaComment, FaHeart, FaReply, FaThumbtack, FaEye, FaEyeSlash, FaTrash } from 'react-icons/fa';
import { collection, addDoc, doc, updateDoc, increment, Timestamp, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../hooks/useUserProfile';
import UserBadge from '../UserBadge';
import DiscussionComments from './DiscussionComments';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import './ForumDiscussion.css';
import adminAvatar from '../../assets/AG.png';

// eslint-disable-next-line no-unused-vars
const SpoilerBlock = ({ content }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    return (
        <div className="spoiler-container">
            <button 
                className="spoiler-button" 
                onClick={() => setIsRevealed(!isRevealed)}
            >
                {isRevealed ? <FaEyeSlash /> : <FaEye />}
                {isRevealed ? ' Masquer le spoiler' : ' Afficher le spoiler'}
            </button>
            <div className={`spoiler-content ${isRevealed ? 'revealed' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

const ForumDiscussion = ({ discussion, collectionName, onReply, onUpdate }) => {
    const [isReplying, setIsReplying] = useState(false);
    const [replyContent, setReplyContent] = useState('');
    const [isLiked, setIsLiked] = useState(false);
    const [localLikes, setLocalLikes] = useState(discussion.likes || 0);
    const [localComments, setLocalComments] = useState(discussion.commentsCount || 0);
    const [showComments, setShowComments] = useState(false);
    const [comments, setComments] = useState([]);
    const [isPinned, setIsPinned] = useState(discussion.pinned || false);
    const { currentUser } = useAuth();
    const { userProfile } = useUserProfile(currentUser?.uid);
    const { userProfile: authorProfile } = useUserProfile(discussion.authorId);
    const isAdmin = currentUser?.uid === "et0ghHazwWPz053JZtaw0Xt8Hox2" || currentUser?.uid === "Np81OfHPMpfbAFoynOLMnduCvd42";
    const isAuthor = currentUser && currentUser.uid === discussion.authorId;
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();

    // Vérifier si l'utilisateur a déjà liké ce post
    useEffect(() => {
        const checkIfLiked = async () => {
            if (!currentUser) return;
            
            try {
                const likesRef = collection(db, `${collectionName}-likes`);
                const q = query(
                    likesRef,
                    where('discussionId', '==', discussion.id),
                    where('userId', '==', currentUser.uid)
                );
                const querySnapshot = await getDocs(q);
                setIsLiked(!querySnapshot.empty);
            } catch (error) {
                console.error('Erreur lors de la vérification du like:', error);
            }
        };

        checkIfLiked();
    }, [currentUser, collectionName, discussion.id]);

    // Pré-charger les commentaires
    useEffect(() => {
        const fetchComments = async () => {
            try {
                const commentsRef = collection(db, `${collectionName}-comments`);
                const q = query(commentsRef, where('discussionId', '==', discussion.id));
                const querySnapshot = await getDocs(q);
                
                const fetchedComments = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                
                // Trier les commentaires par date (du plus récent au plus ancien)
                const sortedComments = fetchedComments.sort((a, b) => 
                    b.createdAt.toDate() - a.createdAt.toDate()
                );
                
                setComments(sortedComments);
            } catch (error) {
                console.error('Erreur lors du chargement des commentaires:', error);
            }
        };

        fetchComments();
    }, [collectionName, discussion.id]);

    // Mettre à jour le compteur local quand les commentaires changent
    useEffect(() => {
        setLocalComments(comments.length);
    }, [comments]);

    const handleLike = async () => {
        if (!currentUser) {
            alert('Vous devez être connecté pour liker un post');
            return;
        }

        if (isLiked) return;

        try {
            await addDoc(collection(db, `${collectionName}-likes`), {
                discussionId: discussion.id,
                userId: currentUser.uid,
                createdAt: Timestamp.now()
            });

            const discussionRef = doc(db, collectionName, discussion.id);
            await updateDoc(discussionRef, {
                likes: increment(1)
            });

            setIsLiked(true);
            setLocalLikes(prev => prev + 1);
        } catch (error) {
            console.error('Erreur lors du like:', error);
        }
    };

    const handleReplySubmit = async (e) => {
        e.preventDefault();
        if (!currentUser) {
            alert('Vous devez être connecté pour répondre');
            return;
        }

        if (!userProfile) {
            alert('Chargement de votre profil en cours... Veuillez réessayer.');
            return;
        }

        if (!replyContent.trim()) {
            alert('Le contenu de la réponse ne peut pas être vide');
            return;
        }

        try {
            const commentData = {
                discussionId: discussion.id,
                content: replyContent,
                authorId: currentUser.uid,
                authorName: userProfile.displayName || 'Anonyme',
                authorProfile: {
                    displayName: userProfile.displayName || 'Anonyme',
                    photoURL: userProfile.photoURL || `https://api.dicebear.com/7.x/avataars/svg?seed=${currentUser.uid}`,
                    badge: userProfile.badge || null,
                    role: userProfile.role || 'user'
                },
                createdAt: Timestamp.now(),
                likes: 0
            };
            
            const commentRef = await addDoc(collection(db, `${collectionName}-comments`), commentData);

            // Mettre à jour le compteur de commentaires dans la discussion
            const discussionRef = doc(db, collectionName, discussion.id);
            await updateDoc(discussionRef, {
                commentsCount: increment(1)
            });

            setReplyContent('');
            setIsReplying(false);
            
            // Ajouter directement le nouveau commentaire à la liste
            const newComment = {
                id: commentRef.id,
                ...commentData
            };
            setComments(prevComments => [newComment, ...prevComments]);
            
            if (onReply) onReply();
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la réponse:', error);
            alert('Une erreur est survenue lors de l\'envoi de la réponse. Veuillez réessayer.');
        }
    };

    const handlePinToggle = async () => {
        if (!isAdmin) return;
        
        try {
            const discussionRef = doc(db, collectionName, discussion.id);
            await updateDoc(discussionRef, {
                pinned: !isPinned
            });
            
            setIsPinned(!isPinned);
            if (onReply) onReply(); // Pour rafraîchir la liste des discussions
        } catch (error) {
            console.error('Erreur lors du changement de statut épinglé:', error);
            alert('Une erreur est survenue lors de la modification du statut épinglé');
        }
    };

    const toggleComments = () => {
        setShowComments(prev => !prev);
    };

    const getAuthorAvatar = () => {
        if (!authorProfile) return '';
        if (authorProfile.role === 'admin') return adminAvatar;
        return authorProfile.photoURL;
    };

    const getAuthorName = () => {
        if (!authorProfile) return 'Chargement...';
        if (authorProfile.role === 'admin') return 'AzureTM';
        return authorProfile.displayName || 'Anonyme';
    };

    const getAuthorBadge = () => {
        if (!authorProfile) return null;
        if (authorProfile.role === 'admin') return { type: 'admin', label: 'Admin' };
        return authorProfile.badge;
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    const renderContent = () => {
        if (!discussion?.content) return null;
        
        const content = discussion.content.replace(/<p>/g, '').replace(/<\/p>/g, '');
        const segments = content.split(/(\[spoiler\].*?\[\/spoiler\])/gs);
        
        return segments.map((segment, index) => {
            if (segment.startsWith('[spoiler]')) {
                const spoilerContent = segment.match(/\[spoiler\](.*?)\[\/spoiler\]/s)[1];
                return <SpoilerBlock key={index} content={spoilerContent} />;
            }
            return <span key={index} dangerouslySetInnerHTML={{ __html: segment }} />;
        });
    };

    const handleDelete = async () => {
        if (!currentUser || (!isAdmin && !isAuthor)) return;
        
        if (window.confirm('Êtes-vous sûr de vouloir supprimer cette discussion ?')) {
            try {
                await deleteDoc(doc(db, collectionName, discussion.id));
            } catch (error) {
                console.error("Erreur lors de la suppression de la discussion:", error);
                alert("Une erreur est survenue lors de la suppression de la discussion.");
            }
        }
    };

    const handleCommentDelete = () => {
        setLocalComments(prev => prev - 1);
    };

    return (
        <div className={`discussion-card ${authorProfile?.role === 'admin' ? 'admin-post' : ''} ${isPinned ? 'pinned-post' : ''}`}>
            <div className="discussion-header">
                <div className="author-info">
                    {isPinned && (
                        <span className="pinned-indicator" title="Discussion épinglée">
                            <FaThumbtack />
                        </span>
                    )}
                    <img 
                        src={getAuthorAvatar()} 
                        alt={getAuthorName()} 
                        className="author-avatar"
                    />
                    <div className="author-info-text">
                        <span className="author-name">{getAuthorName()}</span>
                        <UserBadge badge={getAuthorBadge()} />
                    </div>
                </div>
                <div className="discussion-date">
                    {formatDate(discussion.createdAt)}
                    {isAdmin && (
                        <button 
                            className={`pin-button ${isPinned ? 'pinned' : ''}`}
                            onClick={handlePinToggle}
                            title={isPinned ? "Désépingler la discussion" : "Épingler la discussion"}
                        >
                            <FaThumbtack />
                        </button>
                    )}
                </div>
            </div>
            <div className="discussion-content">
                {renderContent()}
            </div>
            <div className="discussion-footer">
                <div className="discussion-actions">
                    <button 
                        className="action-button"
                        onClick={toggleComments}
                    >
                        <FaComment />
                        <span>{localComments}</span>
                    </button>
                    <button 
                        className={'action-button ' + (isLiked ? 'liked' : '')}
                        onClick={handleLike}
                        disabled={!currentUser || isLiked}
                    >
                        <FaHeart />
                        <span>{localLikes}</span>
                    </button>
                    <button 
                        className="action-button"
                        onClick={() => setIsReplying(!isReplying)}
                    >
                        <FaReply />
                        <span>Répondre</span>
                    </button>
                    {currentUser && discussion.authorId === currentUser.uid && (
                        <div className="author-actions">
                            <button 
                                className="delete-button"
                                onClick={handleDelete}
                                title="Supprimer la discussion"
                            >
                                <FaTrash />
                            </button>
                        </div>
                    )}
                </div>
                {showComments && (
                    <div style={{ marginTop: '1rem', borderTop: '1px solid var(--border-color)', paddingTop: '1rem' }}>
                        <DiscussionComments 
                            discussionId={discussion.id} 
                            forumId={collectionName}
                            onCommentDelete={handleCommentDelete}
                        />
                    </div>
                )}
                {isReplying && (
                    <form onSubmit={handleReplySubmit} className="reply-form">
                        <RichTextEditor
                            value={replyContent}
                            onChange={setReplyContent}
                            placeholder="Votre réponse..."
                        />
                        <div className="form-actions">
                            <button type="button" onClick={() => setIsReplying(false)}>
                                Annuler
                            </button>
                            <button type="submit">
                                Répondre
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
};

export default ForumDiscussion;
