import React from 'react';

const UserAvatar = ({ user, size = 40 }) => {
  const avatarStyle = {
    width: size,
    height: size,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: `${size * 0.4}px`,
    fontWeight: 'bold',
    color: '#ffffff',
    backgroundColor: user.photoURL ? 'transparent' : generateColorFromInitials(user.initials),
    backgroundImage: user.photoURL ? `url(${user.photoURL})` : 'none',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  // Génère une couleur basée sur les initiales
  function generateColorFromInitials(initials) {
    const colors = [
      '#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e',
      '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50',
      '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6', '#f39c12',
      '#d35400', '#c0392b', '#7f8c8d',
    ];
    
    const charSum = initials.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    return colors[charSum % colors.length];
  }

  return (
    <div style={avatarStyle}>
      {!user.photoURL && user.initials}
    </div>
  );
};

export default UserAvatar;
