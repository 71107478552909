import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';

const CookieContext = createContext();

const COOKIE_PREFERENCES_KEY = 'cookie_preferences';
const COOKIE_CONSENT_VERSION = '1.0';
const COOKIE_EXPIRATION = 180; // 6 mois en jours

export const CookieProvider = ({ children }) => {
  const [cookiePreferences, setCookiePreferences] = useState({
    essential: true,
    statistics: false,
    marketing: false,
    version: COOKIE_CONSENT_VERSION,
  });
  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const loadPreferences = useCallback(async () => {
    try {
      const savedPreferences = Cookies.get(COOKIE_PREFERENCES_KEY);
      if (!savedPreferences) {
        setShowBanner(true);
      } else {
        const parsed = JSON.parse(savedPreferences);
        if (parsed.version !== COOKIE_CONSENT_VERSION) {
          setShowBanner(true);
        } else {
          setCookiePreferences(parsed);
        }
      }
    } catch (error) {
      console.error('Erreur lors du chargement des préférences de cookies:', error);
      setShowBanner(true);
    } finally {
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    loadPreferences();
  }, [loadPreferences]);

  const savePreferences = useCallback((preferences) => {
    if (!isInitialized) return;

    const newPreferences = {
      ...preferences,
      version: COOKIE_CONSENT_VERSION,
      essential: true,
    };

    try {
      Cookies.set(COOKIE_PREFERENCES_KEY, JSON.stringify(newPreferences), {
        expires: COOKIE_EXPIRATION,
        sameSite: 'strict',
      });

      setCookiePreferences(newPreferences);
      setShowBanner(false);
      setShowModal(false);

      if (newPreferences.statistics) {
        console.log('Google Analytics activé');
      }

      if (newPreferences.marketing) {
        console.log('Cookies marketing activés');
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde des préférences:', error);
    }
  }, [isInitialized]);

  const acceptAll = useCallback(() => {
    savePreferences({
      essential: true,
      statistics: true,
      marketing: true,
    });
  }, [savePreferences]);

  const rejectAll = useCallback(() => {
    savePreferences({
      essential: true,
      statistics: false,
      marketing: false,
    });
  }, [savePreferences]);

  if (!isInitialized) {
    return null;
  }

  return (
    <CookieContext.Provider
      value={{
        cookiePreferences,
        showBanner,
        showModal,
        setShowModal,
        savePreferences,
        acceptAll,
        rejectAll,
      }}
    >
      {children}
    </CookieContext.Provider>
  );
};

export const useCookies = () => {
  const context = useContext(CookieContext);
  if (!context) {
    throw new Error('useCookies doit être utilisé avec un CookieProvider');
  }
  return context;
};
