import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// eslint-disable-next-line no-unused-vars
import { connectAuthEmulator } from 'firebase/auth';

const firebaseConfig = {
  // Remplacez ces valeurs par vos propres configurations Firebase
  apiKey: "AIzaSyAAQoZdWMvTQp_EJVHytNgxQM5Yyg6FTCg",
  authDomain: "techpassionfr.firebaseapp.com",
  projectId: "techpassionfr",
  storageBucket: "techpassionfr.firebasestorage.app",
  messagingSenderId: "907964008171",
  appId: "1:907964008171:web:6bf682a8d051620f6720ae"
};

// Initialize Firebase
console.log('Initialisation de Firebase avec la configuration:', {
  projectId: firebaseConfig.projectId,
  authDomain: firebaseConfig.authDomain
});
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// Vérifier si nous sommes en mode développement
if (window.location.hostname === 'localhost') {
  console.warn('Application en cours d\'exécution sur localhost');
  console.log('Projet Firebase:', firebaseConfig.projectId);
  console.log('Auth Domain:', firebaseConfig.authDomain);
  console.log('Mode développement détecté, désactivation de l\'émulateur');
  // Décommenter la ligne suivante uniquement si vous voulez utiliser l'émulateur
  // connectAuthEmulator(auth, "http://localhost:9099");
}

// Vérifier la configuration et les permissions
const checkFirebaseSetup = async () => {
  try {
    // Vérifier si nous sommes sur le bon projet
    const projectId = auth.app.options.projectId;
    console.log('Vérification du projet Firebase:', projectId);

    // Vérifier l'état de l'authentification
    const currentUser = auth.currentUser;
    if (currentUser) {
      console.log('Utilisateur actuellement connecté:', {
        email: currentUser.email,
        uid: currentUser.uid,
        projectId: projectId
      });

      // Vérifier le token
      // eslint-disable-next-line no-unused-vars
      const token = await currentUser.getIdToken();
      console.log('Token d\'authentification valide');
    }

    // Vérifier la connexion à Firestore
    const dbInstance = getFirestore(app);
    console.log('Instance Firestore initialisée pour le projet:', dbInstance._databaseId.projectId);

  } catch (error) {
    console.error('Erreur lors de la vérification Firebase:', error);
  }
};

// Exécuter la vérification
checkFirebaseSetup();

// Tester la connexion avec un compte existant
export const testAuth = async () => {
  try {
    console.log('Test de connexion Firebase...');
    const userCredential = await signInWithEmailAndPassword(auth, 'janel@test.com', 'votre_mot_de_passe');
    console.log('Test réussi, utilisateur:', userCredential.user.uid);
    await auth.signOut();
  } catch (error) {
    console.error('Erreur de test Firebase:', error.code, error.message);
  }
};

// Listener pour les changements d'état d'authentification
auth.onAuthStateChanged((user) => {
  if (user) {
    console.log('Utilisateur connecté:', user.email);
    console.log('Détails de l\'utilisateur:', {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      providerData: user.providerData,
      providerId: user.providerData[0]?.providerId,
      authDomain: auth.app.options.authDomain,
      projectId: auth.app.options.projectId
    });
    
    // Vérifier à nouveau la configuration quand un utilisateur se connecte
    checkFirebaseSetup();
  } else {
    console.log('Utilisateur déconnecté');
  }
});
