import React, { useState, useEffect } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import heroContact from '../assets/hero-contact.jpg';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  // Vérification de la connexion Firebase au chargement
  useEffect(() => {
    console.log('Firestore instance:', db);
    if (!db) {
      console.error('Firestore n\'est pas initialisé correctement');
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus({ type: '', message: '' });

    try {
      console.log('Début de la soumission du formulaire');
      console.log('Données à envoyer:', formData);

      // Vérification de l'instance Firestore
      if (!db) {
        throw new Error('Firestore n\'est pas initialisé');
      }

      // Création de la référence à la collection
      const messagesRef = collection(db, 'messages');
      console.log('Référence de la collection créée:', messagesRef);

      // Ajout du document
      const docRef = await addDoc(messagesRef, {
        ...formData,
        createdAt: new Date(),
      });

      console.log('Document créé avec succès, ID:', docRef.id);

      // Réinitialisation du formulaire
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });

      setSubmitStatus({
        type: 'success',
        message: 'Votre message a été envoyé avec succès !'
      });
    } catch (error) {
      console.error('Erreur détaillée lors de l\'envoi du message:', error);
      setSubmitStatus({
        type: 'error',
        message: `Erreur lors de l'envoi : ${error.message}`
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <main>
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-left">
          <div className="hero-content">
            <h1>Contact</h1>
            <p className="hero-subtitle">Restons en contact</p>
          </div>
        </div>
        <img className="hero-image" src={heroContact} alt="Contact TechPassion" />
      </section>

      <section className="page-content">
        <div className="category-section">
          <div className="contact-form">
            {submitStatus.message && (
              <div className={`alert ${submitStatus.type}`}>
                {submitStatus.message}
              </div>
            )}
            
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nom</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <label htmlFor="subject">Sujet</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="6"
                  disabled={isSubmitting}
                />
              </div>

              <div style={{ textAlign: 'center' }}>
                <button 
                  type="submit" 
                  className="submit-btn"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
                </button>
              </div>
            </form>
          </div>

          <div className="contact-info">
            <div className="tech-card">
              <h3>Informations de Contact</h3>
              <p>Email: alinpreda01@gmail.com</p>
              <p>Téléphone: +33 6 76 70 37 38</p>
              <p>Adresse: 210 Rue du Grésivaudan, 38420 Le Versoud</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
