import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';

export const initIPTVSearch = async () => {
  const searchData = [
    // Live TV Section
    {
      title: "Chaînes TV en Direct",
      path: "/iptv#live-tv",
      section: "iptv",
      type: "article",
      keywords: ["tv en direct", "chaînes tv", "live streaming", "télévision"],
      searchableTerms: ["tv en direct", "chaînes tv", "live streaming", "télévision", "direct", "programme tv"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Chaînes Nationales",
      path: "/iptv#live-tv",
      section: "iptv",
      type: "article",
      keywords: ["chaînes nationales", "tf1", "france 2", "france 3", "m6"],
      searchableTerms: ["chaînes nationales", "chaînes françaises", "tf1", "france télévisions", "m6", "canal+"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Chaînes Internationales",
      path: "/iptv#live-tv",
      section: "iptv",
      type: "article",
      keywords: ["chaînes internationales", "bbc", "cnn", "tv5monde", "international"],
      searchableTerms: ["chaînes internationales", "bbc", "cnn", "tv5monde", "chaînes étrangères", "international"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Sport en Direct",
      path: "/iptv#live-tv",
      section: "iptv",
      type: "article",
      keywords: ["sport", "direct", "événements sportifs", "compétitions"],
      searchableTerms: ["sport en direct", "événements sportifs", "compétitions", "matchs", "retransmissions sportives"],
      lastUpdated: new Date().toISOString()
    },

    // Cloud DVR Section
    {
      title: "Enregistrement Cloud",
      path: "/iptv#cloud-dvr",
      section: "iptv",
      type: "article",
      keywords: ["enregistrement", "cloud", "dvr", "programmes"],
      searchableTerms: ["enregistrement cloud", "dvr", "programmes tv", "enregistrement à distance", "stockage cloud"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Stockage Illimité",
      path: "/iptv#cloud-dvr",
      section: "iptv",
      type: "article",
      keywords: ["stockage", "cloud", "illimité", "espace"],
      searchableTerms: ["stockage illimité", "cloud storage", "espace de stockage", "sauvegarde programmes"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Replay TV",
      path: "/iptv#cloud-dvr",
      section: "iptv",
      type: "article",
      keywords: ["replay", "catch-up", "programmes en replay", "rattrapage"],
      searchableTerms: ["replay tv", "catch-up tv", "programmes en replay", "rattrapage tv", "revoir programmes"],
      lastUpdated: new Date().toISOString()
    },
    {
      title: "Téléchargement Hors-ligne",
      path: "/iptv#cloud-dvr",
      section: "iptv",
      type: "article",
      keywords: ["téléchargement", "hors-ligne", "offline", "mobile"],
      searchableTerms: ["téléchargement hors-ligne", "visionnage offline", "programmes hors connexion", "mobile"],
      lastUpdated: new Date().toISOString()
    }
  ];

  try {
    for (const item of searchData) {
      await addDoc(collection(db, 'searchIndex'), item);
    }
    return true;
  } catch (error) {
    return false;
  }
};
