import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { useCookies } from '../contexts/CookieContext';

const CookieModal = () => {
  const { showModal, setShowModal, cookiePreferences, savePreferences } = useCookies();
  const [mounted, setMounted] = useState(false);
  const [localPreferences, setLocalPreferences] = useState(cookiePreferences);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  useEffect(() => {
    if (mounted) {
      setLocalPreferences(cookiePreferences);
    }
  }, [cookiePreferences, mounted]);

  const handleClose = () => {
    if (mounted) {
      setShowModal(false);
      setLocalPreferences(cookiePreferences);
    }
  };

  const handleSave = () => {
    if (mounted) {
      savePreferences(localPreferences);
    }
  };

  const handleChange = (name) => (event) => {
    if (mounted) {
      setLocalPreferences((prev) => ({
        ...prev,
        [name]: event.target.checked,
      }));
    }
  };

  if (!mounted) return null;

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="cookie-preferences-title"
    >
      <DialogTitle id="cookie-preferences-title">
        Préférences des cookies
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          Gérez vos préférences de cookies ci-dessous. Les cookies essentiels ne peuvent pas
          être désactivés car ils sont nécessaires au fonctionnement du site.
        </Typography>

        <FormGroup>
          <Box mb={2}>
            <FormControlLabel
              control={<Switch checked={true} disabled />}
              label="Cookies essentiels"
            />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 3 }}>
              Nécessaires au fonctionnement du site (authentification, préférences de base).
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mb={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={localPreferences.statistics}
                  onChange={handleChange('statistics')}
                />
              }
              label="Cookies statistiques"
            />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 3 }}>
              Nous aident à comprendre comment vous utilisez le site pour l'améliorer
              (Google Analytics).
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mb={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={localPreferences.marketing}
                  onChange={handleChange('marketing')}
                />
              }
              label="Cookies marketing"
            />
            <Typography variant="body2" color="text.secondary" sx={{ ml: 3 }}>
              Permettent d'afficher des publicités pertinentes et de mesurer leur efficacité.
            </Typography>
          </Box>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Enregistrer les préférences
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieModal;
