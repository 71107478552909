import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const PolitiqueConfidentialite = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Politique de Confidentialité
        </Typography>
        
        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            1. Collecte des Informations
          </Typography>
          <Typography paragraph>
            Nous collectons les informations que vous nous fournissez directement lorsque vous utilisez nos services. 
            Cela peut inclure vos informations de connexion, vos préférences de navigation et vos interactions avec notre plateforme.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            2. Utilisation des Données
          </Typography>
          <Typography paragraph>
            Les informations collectées sont utilisées pour :
          </Typography>
          <ul>
            <Typography component="li">Améliorer nos services et votre expérience utilisateur</Typography>
            <Typography component="li">Personnaliser le contenu et les recommandations</Typography>
            <Typography component="li">Assurer la sécurité de votre compte</Typography>
            <Typography component="li">Communiquer avec vous concernant nos services</Typography>
          </ul>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            3. Protection des Données
          </Typography>
          <Typography paragraph>
            Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles 
            contre tout accès non autorisé, modification, divulgation ou destruction.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            4. Cookies et Technologies Similaires
          </Typography>
          <Typography paragraph>
            Nous utilisons des cookies et des technologies similaires pour améliorer votre expérience, 
            comprendre comment nos services sont utilisés et personnaliser notre contenu.
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            5. Partage des Informations
          </Typography>
          <Typography paragraph>
            Nous ne vendons pas vos informations personnelles à des tiers. Nous pouvons partager vos informations 
            uniquement dans les cas suivants :
          </Typography>
          <ul>
            <Typography component="li">Avec votre consentement</Typography>
            <Typography component="li">Pour des raisons légales</Typography>
            <Typography component="li">Avec nos fournisseurs de services de confiance</Typography>
          </ul>
        </Box>

        <Box mb={4}>
          <Typography variant="h5" gutterBottom>
            6. Vos Droits
          </Typography>
          <Typography paragraph>
            Vous avez le droit d'accéder à vos données personnelles, de les corriger, de les supprimer ou d'en 
            limiter le traitement. Vous pouvez également vous opposer au traitement de vos données et exercer 
            votre droit à la portabilité des données.
          </Typography>
        </Box>

        <Box>
          <Typography variant="h5" gutterBottom>
            7. Contact
          </Typography>
          <Typography paragraph>
            Pour toute question concernant notre politique de confidentialité ou pour exercer vos droits, 
            veuillez nous contacter à l'adresse suivante : alinpreda01@gmail.com
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="body2" color="text.secondary">
            Dernière mise à jour : {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PolitiqueConfidentialite;
