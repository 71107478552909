import React, { useState } from 'react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../contexts/AuthContext';
import { useUserProfile } from '../../hooks/useUserProfile';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import './NouvDiscModal.css';

const NouvDiscModal = ({ isOpen, onClose, forumId, onDiscussionUpdate }) => {
    const [newDiscussion, setNewDiscussion] = useState({ title: '', content: '' });
    const { currentUser } = useAuth();
    const { userProfile } = useUserProfile(currentUser?.uid);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!currentUser || !userProfile) return;

        try {
            // Vérifier si l'utilisateur est admin
            const isAdmin = userProfile.role === 'admin';

            // Créer un objet authorProfile avec des valeurs par défaut
            const authorProfile = {
                displayName: userProfile.displayName || 'Utilisateur',
                photoURL: userProfile.photoURL || null,
                role: userProfile.role || 'user'
            };

            await addDoc(collection(db, forumId), {
                ...newDiscussion,
                authorId: currentUser.uid,
                authorName: authorProfile.displayName,
                authorProfile,
                createdAt: serverTimestamp(),
                likes: 0,
                comments: 0,
                pinned: isAdmin
            });
            
            setNewDiscussion({ title: '', content: '' });
            onClose();
            if (onDiscussionUpdate) {
                onDiscussionUpdate();
            }
        } catch (error) {
            console.error('Error adding discussion:', error);
            alert('Une erreur est survenue lors de la création de la discussion');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Nouvelle Discussion</h2>
                    <button onClick={onClose} className="close-button">×</button>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Titre de la discussion"
                            value={newDiscussion.title}
                            onChange={(e) => setNewDiscussion({ ...newDiscussion, title: e.target.value })}
                            className="title-input"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <RichTextEditor
                            value={newDiscussion.content}
                            onChange={(content) => setNewDiscussion({ ...newDiscussion, content })}
                            placeholder="Contenu de votre discussion..."
                        />
                    </div>
                    <button type="submit" className="submit-button">
                        Créer la discussion
                    </button>
                </form>
            </div> 
        </div>
    );
};

export default NouvDiscModal;
