import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Link, useLocation } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import heroTechGadgets from '../assets/hero-tech-gadgets.jpg';
import smartphonesIcon from '../assets/icons/smartphones-icon.svg';
import computersIcon from '../assets/icons/computers-icon.svg';
import iptvIcon from '../assets/icons/iptv-icon.svg';
import gadgetsIcon from '../assets/icons/gadgets-icon.svg';
import './TechGadgets.css';
import ForumDiscussion from '../components/Forum/ForumDiscussion';

const TechGadgets = () => {
    const [discussions, setDiscussions] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const fetchDiscussions = async () => {
            try {
                const discussionsRef = collection(db, 'tech-gadgets-discussions');
                const q = query(discussionsRef, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                
                const discussionsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                    pinned: doc.data().pinned || false,
                    createdAt: doc.data().createdAt || { seconds: 0 }
                }));

                const sortedDiscussions = discussionsData.sort((a, b) => {
                    if (a.pinned && !b.pinned) return -1;
                    if (!a.pinned && b.pinned) return 1;
                    return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
                });

                setDiscussions(sortedDiscussions);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching discussions:", error);
                setLoading(false);
            }
        };

        fetchDiscussions();
    }, []);

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    }, [location]);

    const handleDiscussionUpdate = async () => {
        const discussionsRef = collection(db, 'tech-gadgets-discussions');
        const querySnapshot = await getDocs(discussionsRef);
        
        const discussionsData = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            pinned: doc.data().pinned || false,
            createdAt: doc.data().createdAt || { seconds: 0 }
        }));

        const sortedDiscussions = discussionsData.sort((a, b) => {
            if (a.pinned && !b.pinned) return -1;
            if (!a.pinned && b.pinned) return 1;
            return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0);
        });

        setDiscussions(sortedDiscussions);
    };

    return (
        <main className="tech-gadgets-page">
            <section className="hero">
                <div className="hero-left">
                    <div className="hero-content">
                        <h1>Tech & Gadgets</h1>
                        <p>Découvrez les dernières innovations technologiques et gadgets du moment</p>
                    </div>
                </div>
                <img 
                    className="hero-image"
                    src={heroTechGadgets} 
                    alt="Collection des derniers gadgets technologiques innovants avec leurs fonctionnalités avancées" 
                />
            </section>

            {/* Section Smartphones */}
            <div className="category-section" id="smartphones">
                <div className="section-header">
                    <img src={smartphonesIcon} alt="Icône représentant un smartphone moderne avec écran tactile" className="section-icon" />
                    <div className="section-text">
                        <h2>Smartphones</h2>
                        <p>Tout sur l'univers des smartphones</p>
                    </div>
                </div>
                <div className="content-grid">
                    <article className="tech-card">
                        <Link to="/tech-gadgets/derniers-modeles">
                            <div className="card-content">
                                <h3>Derniers modèles</h3>
                                <p>Découvrez les nouveautés et les tests des derniers smartphones</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/ios-vs-android">
                            <div className="card-content">
                                <h3>iOS vs Android</h3>
                                <p>Comparaison des deux systèmes d'exploitation mobiles majeurs</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/applications-indispensables">
                            <div className="card-content">
                                <h3>Applications indispensables</h3>
                                <p>Sélection des meilleures applications pour votre smartphone</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/problemes-solutions">
                            <div className="card-content">
                                <h3>Problèmes techniques et solutions</h3>
                                <p>Guide de dépannage et solutions aux problèmes courants</p>
                            </div>
                        </Link>
                    </article>
                </div>
            </div>

            {/* Section Ordinateurs */}
            <div className="category-section" id="ordinateurs">
                <div className="section-header">
                    <img src={computersIcon} alt="Icône représentant un ordinateur de bureau avec écran et clavier" className="section-icon" />
                    <div className="section-text">
                        <h2>Ordinateurs</h2>
                        <p>Guide complet pour tout savoir sur les ordinateurs</p>
                    </div>
                </div>
                <div className="content-grid">
                    <article className="tech-card">
                        <Link to="/tech-gadgets/assemblage-pc">
                            <div className="card-content">
                                <h3>Assemblage PC</h3>
                                <p>Guide pas à pas pour monter votre propre ordinateur</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/pc-gaming">
                            <div className="card-content">
                                <h3>PC Gaming</h3>
                                <p>Tout sur les configurations gaming et les performances</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/macos-vs-windows">
                            <div className="card-content">
                                <h3>MacOS vs Windows</h3>
                                <p>Comparatif détaillé des deux systèmes d'exploitation</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/resolution-problemes">
                            <div className="card-content">
                                <h3>Résolution de problèmes et dépannage</h3>
                                <p>Solutions aux problèmes courants et maintenance</p>
                            </div>
                        </Link>
                    </article>
                </div>
            </div>

            {/* Section IPTV */}
            <div className="category-section" id="iptv">
                <div className="section-header">
                    <img src={iptvIcon} alt="Icône représentant une télévision connectée avec accès à Internet" className="section-icon" />
                    <div className="section-text">
                        <h2>IPTV</h2>
                        <p>Tout savoir sur la télévision par internet</p>
                    </div>
                </div>
                <div className="content-grid">
                    <article className="tech-card">
                        <Link to="/tech-gadgets/meilleurs-services-iptv">
                            <div className="card-content">
                                <h3>Meilleurs Services IPTV</h3>
                                <p>Guide des services IPTV fiables et performants</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/configuration-installation-iptv">
                            <div className="card-content">
                                <h3>Configuration et Installation</h3>
                                <p>Guide pas à pas pour configurer votre service IPTV</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/problemes-depannage-iptv">
                            <div className="card-content">
                                <h3>Problèmes & Dépannage</h3>
                                <p>Solutions aux problèmes courants d'IPTV</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/securite-legalite-iptv">
                            <div className="card-content">
                                <h3>Sécurité et utilisation légale</h3>
                                <p>Bonnes pratiques et aspects légaux de l'IPTV</p>
                            </div>
                        </Link>
                    </article>
                </div>
            </div>

            {/* Section Gadgets & Innovations */}
            <div className="category-section" id="gadgets">
                <div className="section-header">
                    <img src={gadgetsIcon} alt="Icône représentant un gadget innovant avec des fonctionnalités avancées" className="section-icon" />
                    <div className="section-text">
                        <h2>Gadgets & Innovations</h2>
                        <p>Découvrez les derniers gadgets technologiques et innovations</p>
                    </div>
                </div>
                <div className="content-grid">
                    <article className="tech-card">
                        <Link to="/tech-gadgets/cameras">
                            <div className="card-content">
                                <h3>Caméras</h3>
                                <p>Actualités et guides sur les caméras numériques et d'action</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/drones">
                            <div className="card-content">
                                <h3>Drones</h3>
                                <p>Tout sur les drones : modèles, pilotage et réglementation</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/montres">
                            <div className="card-content">
                                <h3>Montres</h3>
                                <p>Guide des montres connectées et smartwatches</p>
                            </div>
                        </Link>
                    </article>
                    <article className="tech-card">
                        <Link to="/tech-gadgets/divers-gadgets">
                            <div className="card-content">
                                <h3>Divers</h3>
                                <p>Autres gadgets innovants et technologies émergentes</p>
                            </div>
                        </Link>
                    </article>
                </div>
            </div>

            <section className="discussions-section">
                {loading ? (
                    <div className="loading">Chargement des discussions...</div>
                ) : (
                    <div className="discussions-container">
                        {discussions.map(discussion => (
                            <ForumDiscussion
                                key={discussion.id}
                                discussion={discussion}
                                collectionName="tech-gadgets-discussions"
                                onReply={handleDiscussionUpdate}
                            />
                        ))}
                    </div>
                )}
            </section>
        </main>
    );
};

export default TechGadgets;
