import { collection, getDocs, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import { initHighTechSearch } from './highTechSearchData';
import { initIPTVSearch } from './iptvSearchData';
import { initDevSearch } from './devSearchData';
import { initTechGadgetsSearch } from './techGadgetsSearchData';

// Fonction pour initialiser l'index de recherche avec les données initiales
export async function initializeSearchIndex() {
    try {
        // Nettoyer complètement l'index existant
        const searchIndexRef = collection(db, 'searchIndex');
        const snapshot = await getDocs(searchIndexRef);
        
        if (!snapshot.empty) {
            const batch = writeBatch(db);
            snapshot.docs.forEach((doc) => {
                batch.delete(doc.ref);
            });
            await batch.commit();
        }
        
        // Initialiser les données de recherche pour chaque section
        await initHighTechSearch();
        await initIPTVSearch();
        await initDevSearch();
        await initTechGadgetsSearch();
        
        return true;
    } catch (error) {
        return false;
    }
}
