import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useUserProfile } from '../../hooks/useUserProfile';
import { 
    doc, 
    deleteDoc, 
    updateDoc, 
    increment, 
    collection, 
    addDoc, 
    Timestamp, 
    query, 
    where, 
    getDocs 
} from 'firebase/firestore';
import { db } from '../../firebase';
import { FaTrash, FaHeart, FaEye, FaEyeSlash } from 'react-icons/fa';
import UserBadge from '../UserBadge';
import UserAvatar from '../UserAvatar'; 
import './DiscussionComments.css';

const SpoilerBlock = ({ content }) => {
    const [isRevealed, setIsRevealed] = useState(false);

    return (
        <div className="spoiler-container">
            <button 
                className="spoiler-button" 
                onClick={() => setIsRevealed(!isRevealed)}
            >
                {isRevealed ? <FaEyeSlash /> : <FaEye />}
                {isRevealed ? ' Masquer le spoiler' : ' Afficher le spoiler'}
            </button>
            <div className={`spoiler-content ${isRevealed ? 'revealed' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

const Comment = ({ comment, collectionName, onDelete, discussionId }) => {
    const { currentUser } = useAuth();
    const { userProfile: authorProfile } = useUserProfile(comment.authorId);
    const isAdmin = currentUser?.uid === "et0ghHazwWPz053JZtaw0Xt8Hox2" || currentUser?.uid === "Np81OfHPMpfbAFoynOLMnduCvd42";
    const isAuthor = currentUser && currentUser.uid === comment.authorId;
    const [isLiking, setIsLiking] = useState(false);
    const [isLiked, setIsLiked] = useState(false);
    const [localLikes, setLocalLikes] = useState(comment.likes || 0);
    // eslint-disable-next-line no-unused-vars
    const [spoilerStates, setSpoilerStates] = useState({});

    // Vérifier si l'utilisateur a déjà liké ce commentaire
    useEffect(() => {
        const checkIfLiked = async () => {
            if (!currentUser) return;
            
            try {
                const likesRef = collection(db, `${collectionName}-comments-likes`);
                const q = query(
                    likesRef,
                    where('commentId', '==', comment.id),
                    where('userId', '==', currentUser.uid)
                );
                const querySnapshot = await getDocs(q);
                setIsLiked(!querySnapshot.empty);
            } catch (error) {
                console.error('Erreur lors de la vérification du like:', error);
            }
        };

        checkIfLiked();
    }, [currentUser, collectionName, comment.id]);

    const handleLike = async () => {
        if (!currentUser || isLiking) return;

        if (isLiked) return;

        setIsLiking(true);
        try {
            // Ajouter le like dans la collection des likes
            await addDoc(collection(db, `${collectionName}-comments-likes`), {
                commentId: comment.id,
                userId: currentUser.uid,
                createdAt: Timestamp.now()
            });

            // Mettre à jour le compteur de likes dans le commentaire
            const commentRef = doc(db, `${collectionName}-comments`, comment.id);
            await updateDoc(commentRef, {
                likes: increment(1)
            });

            setIsLiked(true);
            setLocalLikes(prev => prev + 1);
        } catch (error) {
            console.error('Erreur lors du like:', error);
        } finally {
            setIsLiking(false);
        }
    };

    const handleDelete = async () => {
        if (!isAdmin && !isAuthor) return;

        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce commentaire ?')) {
            try {
                // Supprimer le commentaire
                await deleteDoc(doc(db, `${collectionName}-comments`, comment.id));

                // Mettre à jour le compteur de commentaires dans la discussion
                const discussionRef = doc(db, collectionName, discussionId);
                await updateDoc(discussionRef, {
                    commentsCount: increment(-1)
                });

                if (onDelete) onDelete(comment.id);
            } catch (error) {
                console.error('Erreur lors de la suppression du commentaire:', error);
            }
        }
    };

    const renderContent = () => {
        if (!comment.content) return null;
        
        const content = comment.content.replace(/<p>/g, '').replace(/<\/p>/g, '');
        const segments = content.split(/(\[spoiler\].*?\[\/spoiler\])/gs);
        
        return segments.map((segment, index) => {
            if (segment.startsWith('[spoiler]')) {
                const spoilerContent = segment.match(/\[spoiler\](.*?)\[\/spoiler\]/s)[1];
                return <SpoilerBlock key={index} content={spoilerContent} />;
            }
            return <span key={index} dangerouslySetInnerHTML={{ __html: segment }} />;
        });
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return '';
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat('fr-FR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        }).format(date);
    };

    if (!authorProfile) return null;

    return (
        <div className="comment">
            <div className="comment-header">
                <div className="comment-author">
                    <UserAvatar 
                        user={authorProfile}
                        size={32}
                    />
                    <div className="author-info">
                        <span className="author-name">
                            {authorProfile.displayName || 'Anonyme'}
                        </span>
                        <UserBadge badge={authorProfile.badge} />
                    </div>
                </div>
                <div className="comment-date">
                    {formatDate(comment.createdAt)}
                </div>
            </div>
            <div className="comment-content">
                {renderContent()}
            </div>
            <div className="comment-actions">
                <button 
                    className={`like-button ${isLiked ? 'liked' : ''}`}
                    onClick={handleLike}
                    disabled={isLiked}
                    title={currentUser ? "J'aime" : "Connectez-vous pour aimer"}
                >
                    <FaHeart />
                    <span className="like-count">{localLikes}</span>
                </button>
                {(isAdmin || isAuthor) && (
                    <button 
                        className="delete-button"
                        onClick={handleDelete}
                        title="Supprimer le commentaire"
                    >
                        <FaTrash />
                    </button>
                )}
            </div>
        </div>
    );
};

const DiscussionComments = ({ discussionId, forumId, onCommentDelete }) => {
    const { currentUser } = useAuth();
    // eslint-disable-next-line no-unused-vars
    const { userProfile } = useUserProfile(currentUser?.uid);
    const [comments, setComments] = useState([]);

    const fetchComments = useCallback(async () => {
        try {
            const commentsRef = collection(db, `${forumId}-comments`);
            const q = query(commentsRef, where('discussionId', '==', discussionId));
            const querySnapshot = await getDocs(q);
            const commentsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setComments(commentsData);
        } catch (error) {
            console.error('Erreur lors de la récupération des commentaires:', error);
        }
    }, [forumId, discussionId]);

    useEffect(() => {
        fetchComments();
    }, [fetchComments]);

    const handleCommentDelete = (commentId) => {
        setComments(prevComments => prevComments.filter(comment => comment.id !== commentId));
        if (onCommentDelete) onCommentDelete();
    };

    if (!comments || comments.length === 0) {
        return <div className="no-comments">Aucun commentaire pour le moment</div>;
    }

    return (
        <div className="comments-section">
            <div className="comments-list">
                {comments.map(comment => (
                    <Comment
                        key={comment.id}
                        comment={comment}
                        collectionName={forumId}
                        discussionId={discussionId}
                        onDelete={handleCommentDelete}
                    />
                ))}
            </div>
        </div>
    );
};

export default DiscussionComments;
