import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Paper, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { useCookies } from '../contexts/CookieContext';

const CookieBanner = () => {
  const { showBanner, acceptAll, rejectAll, setShowModal } = useCookies();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  if (!mounted || !showBanner) return null;

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
        zIndex: 1000,
        backgroundColor: 'background.paper',
        borderTop: '1px solid',
        borderColor: 'divider',
        boxShadow: 3,
      }}
    >
      <Box maxWidth="lg" mx="auto">
        <Typography variant="body1" gutterBottom>
          Nous utilisons des cookies pour améliorer votre expérience sur notre site. 
          Certains sont essentiels au fonctionnement du site, tandis que d'autres nous aident 
          à l'améliorer et à vous offrir un contenu personnalisé.
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Pour en savoir plus, consultez notre{' '}
          <Link
            to="/politique-confidentialite"
            style={{ color: 'inherit', textDecoration: 'underline' }}
            onClick={() => setShowModal(false)}
          >
            politique de confidentialité
          </Link>
          .
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          mt={2}
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => mounted && setShowModal(true)}
          >
            Personnaliser
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => mounted && rejectAll()}
          >
            Tout refuser
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => mounted && acceptAll()}
          >
            Tout accepter
          </Button>
        </Stack>
      </Box>
    </Paper>
  );
};

export default CookieBanner;
