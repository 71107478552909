import React from 'react';
import './MentionsLegales.css';

const MentionsLegales = () => {
    return (
        <div className="legal-container">
            <header className="legal-header">
                <h1>Mentions Légales</h1>
                <p>Informations légales et conditions d'utilisation de TechPassion</p>
            </header>

            <section className="legal-section">
                <h2>1. Informations Légales</h2>
                <p>Le site TechPassion est édité par :</p>
                <div className="contact-info">
                    <p><strong>Nom de la société :</strong> AG Developpement</p>
                    <p><strong>Forme juridique :</strong> Micro-entreprise</p>
                    <p><strong>Siège social :</strong> 210 Rue du Grésivaudan - 38420 Le Versoud</p>
                    <p><strong>SIRET :</strong> 924 037 625 00010</p>
                    <p><strong>Contact :</strong> alinpreda01@gmail.com</p>
                </div>
            </section>

            <section className="legal-section">
                <h2>2. Conditions d'Utilisation</h2>
                
                <h3>2.1 Accès au site</h3>
                <p>L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale.</p>

                <h3>2.2 Contenu du site</h3>
                <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>

                <h3>2.3 Gestion du site</h3>
                <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :</p>
                <ul>
                    <li>Suspendre, interrompre ou limiter l'accès à tout ou partie du site</li>
                    <li>Supprimer toute information pouvant en perturber le fonctionnement</li>
                    <li>Suspendre le site afin de procéder à des mises à jour</li>
                </ul>
            </section>

            <section className="legal-section">
                <h2>3. Protection des Données Personnelles</h2>
                <p>Conformément au Règlement Général sur la Protection des Données (RGPD), vous disposez d'un droit d'accès, de rectification et de suppression de vos données. Les informations recueillies sur ce site sont traitées par TechPassion pour les finalités suivantes :</p>
                <ul>
                    <li>Gestion des comptes utilisateurs</li>
                    <li>Envoi de newsletters (avec consentement)</li>
                    <li>Amélioration de l'expérience utilisateur</li>
                    <li>Analyses statistiques anonymisées</li>
                </ul>

                <h3>3.1 Cookies</h3>
                <p>Le site utilise des cookies pour améliorer l'expérience de navigation. En continuant à naviguer sur ce site, vous acceptez l'utilisation de cookies conformément à notre politique de confidentialité.</p>
            </section>

            <section className="legal-section">
                <h2>4. Limitations de Responsabilité</h2>
                <p>TechPassion ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications techniques requises, soit de l'apparition d'un bug ou d'une incompatibilité.</p>
                
                <h3>4.1 Contenu des Utilisateurs</h3>
                <p>TechPassion n'est pas responsable du contenu publié par ses utilisateurs sur la plateforme. Les opinions, commentaires et contenus partagés par les utilisateurs n'engagent que leurs auteurs respectifs. TechPassion se réserve néanmoins le droit de supprimer tout contenu inapproprié ou illégal sans préavis.</p>

                <h3>4.2 Liens Externes</h3>
                <p>Le site peut contenir des liens vers d'autres sites internet ou sources externes. TechPassion n'ayant pas le contrôle de ces sites et sources externes, nous ne pouvons être tenus responsables de leur contenu, publicités, produits, services ou tout autre élément disponible sur ces sites.</p>
            </section>

            <section className="legal-section">
                <h2>5. Règles d'Utilisation</h2>
                
                <h3>5.1 Respect et Comportement</h3>
                <p>Les utilisateurs de TechPassion s'engagent à :</p>
                <ul>
                    <li>Traiter les autres utilisateurs avec respect et courtoisie</li>
                    <li>S'abstenir de tout propos discriminatoire, haineux ou offensant</li>
                    <li>Ne pas harceler ou intimider d'autres utilisateurs</li>
                    <li>Éviter les attaques personnelles et les critiques non constructives</li>
                </ul>

                <h3>5.2 Contenu Interdit</h3>
                <p>Il est strictement interdit de publier :</p>
                <ul>
                    <li>Du contenu illégal ou faisant la promotion d'activités illégales</li>
                    <li>Du contenu à caractère pornographique ou sexuellement explicite</li>
                    <li>Des informations personnelles d'autres utilisateurs sans leur consentement</li>
                    <li>Du contenu protégé par des droits d'auteur sans autorisation</li>
                    <li>Des virus ou tout autre code malveillant</li>
                </ul>

                <h3>5.3 Responsabilité des Utilisateurs</h3>
                <p>Les utilisateurs sont entièrement responsables :</p>
                <ul>
                    <li>Du contenu qu'ils publient sur la plateforme</li>
                    <li>De la véracité des informations partagées</li>
                    <li>Du respect des droits de propriété intellectuelle</li>
                    <li>Des conséquences directes ou indirectes de leurs publications</li>
                </ul>

                <h3>5.4 Modération</h3>
                <p>TechPassion se réserve le droit de :</p>
                <ul>
                    <li>Supprimer tout contenu ne respectant pas ces règles</li>
                    <li>Suspendre ou bannir les utilisateurs ne respectant pas ces conditions</li>
                    <li>Signaler aux autorités compétentes tout contenu illégal</li>
                    <li>Modifier ces règles d'utilisation à tout moment</li>
                </ul>
            </section>

            <div className="date-update">
                Dernière mise à jour : 7 décembre 2023
            </div>
        </div>
    );
};

export default MentionsLegales;
