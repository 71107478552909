import React, { useState } from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { 
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { setCookie } from '../services/cookieService';
import AvatarSelector from './AvatarSelector';
import '../styles/AuthModal.css';

const AuthModalContent = ({ isOpen, onClose }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [isLoginForm, setIsLoginForm] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState('');

  const handleClose = () => {
    setError('');
    setSuccess('');
    setEmail('');
    setPassword('');
    setUsername('');
    setIsLoginForm(true);
    setIsForgotPassword(false);
    onClose();
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('handleLogin called', { email, password });
    
    if (!email || !password) {
      console.log('Missing fields');
      setError('Veuillez remplir tous les champs');
      return;
    }
    
    setError('');
    setIsLoading(true);

    try {
      console.log('Tentative de connexion avec:', { email });
      
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha('login');
          console.log('reCAPTCHA token obtained:', !!token);
          if (token) {
            setCookie('recaptcha_token', token, 1);
          }
        } catch (recaptchaError) {
          console.error('reCAPTCHA error:', recaptchaError);
        }
      } else {
        console.log('executeRecaptcha not available');
      }

      console.log('Calling signInWithEmailAndPassword');
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Connexion réussie:', userCredential.user.email);
      
      setIsLoading(false);
      handleClose();
    } catch (err) {
      console.error('Login error:', err);
      let errorMessage = 'Échec de la connexion';
      switch (err.code) {
        case 'auth/user-not-found':
          errorMessage = 'Aucun compte ne correspond à cet email';
          break;
        case 'auth/wrong-password':
        case 'auth/invalid-credential':
          errorMessage = 'Identifiants incorrects';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Trop de tentatives. Réessayez plus tard';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Format d\'email invalide';
          break;
        default:
          errorMessage = err.message;
      }
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!email || !password || !username) {
      setError('Veuillez remplir tous les champs');
      return;
    }

    if (password.length < 6) {
      setError('Le mot de passe doit contenir au moins 6 caractères');
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      console.log('Tentative d\'inscription avec:', { email, username });
      
      if (executeRecaptcha) {
        try {
          const token = await executeRecaptcha('signup');
          if (token) {
            setCookie('recaptcha_token', token, 1);
          }
        } catch (recaptchaError) {
          console.error('reCAPTCHA error:', recaptchaError);
        }
      }

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Inscription réussie:', userCredential.user.email);
      
      await updateProfile(userCredential.user, {
        displayName: username,
        photoURL: selectedAvatar || '/default-avatar.png'
      });

      await setDoc(doc(db, 'users', userCredential.user.uid), {
        id: userCredential.user.uid,
        email: email,
        displayName: username,
        photoURL: selectedAvatar || '/default-avatar.png',
        role: 'user',
        createdAt: new Date().toISOString()
      });

      await userCredential.user.reload();
      
      setSuccess('Compte créé avec succès !');
      setIsLoading(false);
      setTimeout(() => {
        handleClose();
        window.location.reload(); // Force le rafraîchissement de la page
      }, 2000);
    } catch (err) {
      console.error('Signup error:', err);
      let errorMessage = 'Échec de la création du compte';
      switch (err.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'Cet email est déjà utilisé';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Format d\'email invalide';
          break;
        case 'auth/weak-password':
          errorMessage = 'Le mot de passe est trop faible (minimum 6 caractères)';
          break;
        default:
          errorMessage = err.message;
      }
      setError(errorMessage);
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Veuillez entrer votre email');
      return;
    }
    
    setError('');
    setIsLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccess('Email de réinitialisation envoyé !');
      setTimeout(() => {
        setIsForgotPassword(false);
      }, 2000);
    } catch (err) {
      console.error('Password reset error:', err);
      setError('Erreur lors de l\'envoi de l\'email de réinitialisation');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="auth-modal-overlay">
      <div className="auth-modal-content">
        <button className="auth-modal-close-button" onClick={handleClose}>×</button>
        
        <h2 className="auth-modal-title">
          {isForgotPassword 
            ? 'Réinitialisation du mot de passe'
            : isLoginForm 
              ? 'Connexion' 
              : 'Inscription'}
        </h2>
        
        {error && <div className="auth-modal-error-message">{error}</div>}
        {success && <div className="auth-modal-success-message">{success}</div>}
        
        <form 
          onSubmit={(e) => {
            console.log('Form submitted', { isLoginForm, isForgotPassword });
            if (isForgotPassword) {
              handleForgotPassword(e);
            } else if (isLoginForm) {
              handleLogin(e);
            } else {
              handleSignUp(e);
            }
          }}
          className="auth-modal-form"
        >
          <div className="auth-modal-form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="auth-modal-form-input"
              required
            />
          </div>
          
          {!isForgotPassword && (
            <div className="auth-modal-form-group">
              <input
                type="password"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="auth-modal-form-input"
                required
              />
            </div>
          )}
          
          {!isLoginForm && !isForgotPassword && (
            <>
              <div className="auth-modal-form-group">
                <input
                  type="text"
                  placeholder="Nom d'utilisateur"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="auth-modal-form-input"
                  required
                />
              </div>
              <AvatarSelector
                selectedAvatar={selectedAvatar}
                onSelect={setSelectedAvatar}
              />
            </>
          )}
          
          <button
            type="submit"
            className="auth-modal-submit-btn"
            disabled={isLoading}
          >
            {isLoading ? 'Chargement...' : 
              isForgotPassword ? 'Envoyer le lien' :
                isLoginForm ? 'Se connecter' : 'S\'inscrire'}
          </button>
        </form>
        
        <div className="auth-modal-footer">
          {!isForgotPassword && (
            <button
              className="auth-modal-link-button"
              onClick={() => setIsLoginForm(!isLoginForm)}
            >
              {isLoginForm ? 'Créer un compte' : 'Déjà inscrit ?'}
            </button>
          )}
          
          {isLoginForm && !isForgotPassword && (
            <button
              className="auth-modal-link-button"
              onClick={() => setIsForgotPassword(true)}
            >
              Mot de passe oublié ?
            </button>
          )}
          
          {isForgotPassword && (
            <button
              className="auth-modal-link-button"
              onClick={() => setIsForgotPassword(false)}
            >
              Retour à la connexion
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const AuthModal = (props) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Ldq4ZUqAAAAAPCepdQZLI9rBL5t9uBCscQE99iN"
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body',
        nonce: undefined,
      }}
      container={{
        parameters: {
          badge: 'inline',
          size: 'invisible'
        }
      }}
      language="fr"
      useRecaptchaNet={true}
      useEnterprise={false}
    >
      <AuthModalContent {...props} />
    </GoogleReCaptchaProvider>
  );
};

export default AuthModal;
